import React, { useCallback, useMemo } from 'react';
import { Asset } from '@commonstock/common/src/types';
import { formatSymbol } from '@commonstock/common/src/utils/format';
import { usePortfolio } from '../portfolio/PortfolioHooks';
import { Star } from '../../theme/NewIcons';
import { SearchItem } from './SearchInput';
import { Colors } from '../../theme/constants';
import { CSText } from '../../composing/CSText';
import { SpacerH } from '../../composing/Spacing';
import { css } from '@linaria/core';
type Props = {
  active: boolean;
  asset: Asset;
  onMouseEnter: () => any;
  onSelect: (asset: Asset) => void;
  inPage?: boolean;
  compact?: boolean;
};

const AssetResult = ({
  active,
  asset,
  onMouseEnter,
  onSelect,
  inPage,
  compact
}: Props) => {
  const {
    watchlist
  } = usePortfolio();
  const isOnWatchList = useMemo(() => watchlist.some(item => item.asset_symbol === asset.symbol), [asset, watchlist]);
  const onClick = useCallback(() => {
    onSelect(asset);
  }, [onSelect, asset]);
  return <SearchItem active={active} onClick={onClick} onMouseEnter={onMouseEnter} inPage={!!inPage || compact}>
      <CSText bold color={Colors.TextPrimary}>
        {formatSymbol(asset.symbol, asset.type)}
      </CSText>
      <SpacerH rem={0.25} />
      <CSText color={Colors.TextSecondary} ellipsis className={assetName}>
        {asset.short_name}
      </CSText>
      {isOnWatchList && !compact && <SpacerH rem={0.5} />}
      {isOnWatchList && !compact && <Star active />}
    </SearchItem>;
};

export default AssetResult;
const assetName = "a9fabgt";

require("../../../.linaria-cache/packages/oxcart/src/scopes/search/AssetResult.linaria.module.css");