import { useCallback, useState } from 'react'

function useDragFile(receiveFiles: (file: File[]) => any) {
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  const onDragOver = useCallback(
    e => {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      if (!isDraggedOver) setIsDraggedOver(true)
    },
    [isDraggedOver]
  )

  const onDrop = useCallback(
    async e => {
      e.preventDefault()
      setIsDraggedOver(false)
      const files: File[] = Object.values(e.dataTransfer.files)
      const droppedFiles = files.filter(f => f.type.includes('image'))
      receiveFiles(droppedFiles)
    },
    [receiveFiles]
  )

  const onDragLeave = useCallback(() => {
    setIsDraggedOver(false)
  }, [setIsDraggedOver])

  return { dragHandlers: { onDragOver, onDrop, onDragLeave }, isDraggedOver }
}

export default useDragFile
