import { createContext } from 'react'
import { Gif } from '@commonstock/common/src/api/gifs'
import { AssetV1 } from '@commonstock/common/src/types'
import { MentionAttachments, ProfileMention } from '@commonstock/common/src/types/mentions'

// MessageInput will ignore these keys when the helper is active. Ideally we would rely on event propogation for this,
// but doesnt work in this case because event propogation relies on the focused element, and we want the helper to work
// regardless of focus.
// refactor opportunity: replace with a keyboard coordinator above all of the message input components.
export const HelperOwnedKeys = ['Enter', 'Tab', 'ArrowUp', 'ArrowDown']

export type Listener = (el: HTMLDivElement) => void

export type HelperPayload =
  | { type: HelperType.Asset; asset: AssetV1 }
  | { type: HelperType.User; profile: ProfileMention }

export enum HelperType {
  Asset = '$',
  User = '@'
}

export type Helper = {
  type: false | HelperType
  search: false | string
  leftText: false | string
  onSelect: (link: string, text: string, payload: HelperPayload, preventSpace: boolean) => void
}

export type TextEditorValue = {
  getMarkdown: () => string
  getPlaintext: () => string
  clear: () => void
  input: JSX.Element | null
  inputRef: HTMLDivElement | null
  insertFiles: (files: Array<File | string | Gif>, isMediaLink?: boolean) => void
  helper: Helper
  startHelper: (type: HelperType) => void
  listen: (cb: Listener) => () => void
  focus: (toStart?: boolean) => void
  getInputRange: () => Range | null
  canSave: boolean
  concat: (text: string) => void
  getPlaintextLength: () => number
  overrideText: (text: string) => void
  getMentions: () => MentionAttachments
}

export const TextEditorContext = createContext<TextEditorValue>({
  clear: () => {},
  insertFiles: () => {},
  input: null,
  inputRef: null,
  listen: () => () => {},
  getMarkdown: () => '',
  getPlaintext: () => '',
  canSave: false,
  helper: {
    type: false,
    search: '',
    leftText: '',
    onSelect: () => {}
  },
  startHelper: () => {},
  focus: () => {},
  getInputRange: () => null,
  concat: () => null,
  getPlaintextLength: () => 0,
  overrideText: () => {},
  getMentions: () => {
    return {}
  }
})
TextEditorContext.displayName = 'TextEditorContext'
