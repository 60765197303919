import { createUseFetchAction, createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { FeedPoll, FeedPrediction, PostFeedItem, PostFeedOutput } from './feed'
import { AssetMention, ProfileMention } from '../types/mentions'
import { AssetType } from '../types'
import { Tag } from './tag'

export type PollData = {
  question: string
  options: string[]
  duration: number
}

export enum PredictionComparator {
  OVER = 'over',
  UNDER = 'under'
}

export enum TargetDates {
  '1 day' = '1D',
  '2 days' = '2D',
  '3 days' = '3D',
  '1 week' = '1W',
  '2 weeks' = '2W',
  '1 month' = '1M'
}

export type PredictionRequest = {
  key_values: {
    symbol: string
    type: AssetType
    comparator: PredictionComparator
    price: number | null
  }
  duration?: number
  period: TargetDates
}

export const BASE_POST_PATH = '/feed/v3/memo/'
type UuidParams = { meta: { uuid: string } }
export const useGetPost = createUseFetch<PostFeedItem, UuidParams>({
  key: 'get-post',
  path: ({ meta: { uuid } }) => `${BASE_POST_PATH}${uuid}`
})

export const useGetPostAction = createUseFetchAction<PostFeedItem, UuidParams>({
  key: 'get-post-action',
  path: ({ meta: { uuid } }) => `${BASE_POST_PATH}${uuid}`
})

export type CreatePostParams = {
  json: {
    title?: string
    body: string
    media: string[]
    publish_content?: boolean
    poll?: PollData
  }
}

// This Schema is uncomplete, but we keep it because we only use the uuid
export type CreatePostPayload = {
  asset_mentions: AssetMention[]
  user_mentions: ProfileMention[]
  summary: string
  title: string
  uuid: string
}
export const useCreatePostAction = createUseFetchAction<CreatePostPayload, CreatePostParams>({
  key: 'create-post',
  method: HttpMethods.Post,
  path: '/user-content/memos/create'
})

export type UpdatePostParams = {
  json: {
    uuid: string
    title?: string
    body: string
    media: string[]
    publish_content?: boolean
    poll?: PollData
  }
}

export const useUpdatePostAction = createUseFetchAction<CreatePostPayload, UpdatePostParams>({
  key: 'update-post',
  method: HttpMethods.Put,
  path: ({ json: { uuid } }) => `/user-content/memos/${uuid}`
})

export const useDeletePostAction = createUseFetchAction<string, UuidParams>({
  key: 'delete-post',
  method: HttpMethods.Delete,
  path: params => `/user-content/memos/${params.meta.uuid}`
})

export const useUnarchivePostAction = createUseFetchAction<void, UuidParams>({
  key: 'unarchive-post',
  method: HttpMethods.Post,
  path: params => `/user-content/memos/${params.meta.uuid}/unarchive`
})

type PinPostParams = {
  meta: { uuid: string }
  json: { pinned: boolean }
}

export const usePinPostAction = createUseFetchAction<string, PinPostParams>({
  key: 'pin-post',
  method: HttpMethods.Post,
  path: params => `/user-content/v2/memos/${params.meta.uuid}/pinned`
})

const pagerOptions: PagerOptions<PostFeedOutput, any> = {
  getCursor: (page, resource) => {
    let list = resource?.success?.payload?.items
    let last = list && list[list.length - 1]
    return { query: { limit: 10, after_uuid: last?.uuid } }
  },
  // @NOTE: checking payload since BE is returning null for empty lists
  isTerminal: resource =>
    !!(resource.success && (!resource.success.payload || resource.success.payload.items.length < 10))
}

type UserPostsParams = {
  query: {
    target_uuid: string
    before_uuid?: string
    after_uuid?: string
    limit?: number
  }
}
export const USER_POSTS_PATH = '/feed/v3/user-memos'
export const useGetUsersPosts = createUsePagedFetch<PostFeedOutput, UserPostsParams>(
  {
    key: 'get-users-posts',
    path: USER_POSTS_PATH,
    cache: CacheType.Disk
  },
  pagerOptions
)

export const useGetDraftPosts = createUseFetch<PostFeedItem[], void>({
  key: 'get-draft-posts',
  path: `/user-content/v2/drafts`
})

type DraftPostParams = { meta: { uuid: string } }
export const useGetDraftPostAction = createUseFetchAction<PostFeedItem, DraftPostParams>({
  key: 'get-draft-post',
  path: ({ meta: { uuid } }) => `/user-content/v2/drafts/${uuid}`
})

type HydrationParams = {
  json: {
    urls: string[]
  }
}

export type HydratedOutput = {
  [key: string]: {
    description: string
    destination: string | null
    display_url: string
    icon: string
    image: string | null
    site_name: string
    title: string
    url: string
    successfully_hydrated: boolean
  }
}
export type HydratedLinkItem = {
  description: string
  destination: string | null
  display_url: string
  icon: string
  image: string | null
  site_name: string
  title: string
  url: string
}

export const useGetHydratedLink = createUseFetchAction<HydratedOutput, HydrationParams>({
  key: 'hydrate-link',
  path: '/link/hydrate',
  method: HttpMethods.Post,
  cacheTime: 0
})

type VotePollParams = {
  meta: {
    uuid: string
  }
  json: {
    poll_uuid: string
    user_uuid: string
    option_uuid: string
  }
}
export const useVotePollAction = createUseFetchAction<FeedPoll, VotePollParams>({
  key: 'vote-poll',
  path: ({ meta: { uuid } }) => `/user-content/v2/memos/${uuid}/vote`,
  method: HttpMethods.Post
})

type VotePredictionParams = {
  meta: {
    uuid: string
  }
  json: {
    prediction_uuid: string
    user_uuid: string
    agreed: boolean
  }
}

export const useVotePredictionAction = createUseFetchAction<FeedPrediction, VotePredictionParams>({
  key: 'vote-prediction',
  path: ({ meta: { uuid } }) => `/user-content/v2/memos/${uuid}/predict`,
  method: HttpMethods.Post
})

type SuggestedTagsParams = {
  json: {
    assets: string[]
  }
}

export const useGetSuggestedTags = createUseFetchAction<Tag[], SuggestedTagsParams>({
  key: 'get-suggested-tags',
  method: HttpMethods.Post,
  path: '/tags/suggested'
})
