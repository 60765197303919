import { FeedType } from '@commonstock/common/src/api/feed'
import { useRouter } from 'next/router'
import { Routes } from 'src/scopes/nav/constants'

export function useFeedType() {
  const router = useRouter()

  let feedType: FeedType | undefined
  switch (router.pathname) {
    case Routes.Home:
      feedType = FeedType.TOP
      break
    case Routes.New:
      feedType = FeedType.NEW
      break
    case Routes.Following:
      feedType = FeedType.FOLLOWING
      break
    default:
      break
  }

  return {
    feedType
  }
}
