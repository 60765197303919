import { createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import cacheLast from '@commonstock/client/src/utils/cacheLast'
import { Asset, ChartData, Periods } from '../types'
import { HydratedUserProfile } from './profile'

export type AssetChartData = ChartData & {
  asset: Asset
}

type AssetChartDataParams = {
  meta: {
    symbol: string
    type: string
    period: string
  }
  headers?: any
}

export const useGetAssetChartData = createUseFetch<AssetChartData, AssetChartDataParams>({
  key: 'asset-chart',
  path: ({ meta: { symbol, type, period } }) => `/mds/asset/${symbol}:${type}/chart/${period}`,
  isPublic: true
})

export type TopInvestor = {
  as_of: string
  created_at: string
  period: string
  updated_at: string
  user: HydratedUserProfile
  period_return: number
}

type TopAssetInvestorsParams = {
  meta: {
    symbol: string
    type: string
  }
  query: {
    limit: number
    calc_type: 'simple' | 'time-weighted'
  }
}

export const useGetTopAssetInvestors = createUseFetch<TopInvestor[], TopAssetInvestorsParams>({
  key: 'top-asset-investors',
  path: ({ meta: { symbol, type } }) => `/pds/${symbol}:${type}/top-investors`,
  isPublic: true
})

export enum LeaderboardFilter {
  Follow = 'follow',
  Global = 'global'
}

type LeaderboardParams = {
  query: {
    limit?: number
    period?: Periods
    type?: LeaderboardFilter
  }
}

type LeaderboardWithCalcParams = {
  query: {
    limit?: number
    period?: Periods
    type?: LeaderboardFilter
    calc_type: 'simple' | 'time-weighted'
  }
}

export const useGetTopInvestors = createUseFetch<TopInvestor[], LeaderboardWithCalcParams>({
  key: 'top-investors',
  path: '/pds/top-investors',
  isPublic: true
})

export type FollowerLeaderboardUser = {
  user: HydratedUserProfile
  rank: {
    follower_value: number
    position: number
    period_change_value: number
    reference_value: number
    period_change_percent: number | null
  }
}
export type FollowerLeaderboard = {
  users: FollowerLeaderboardUser[]
}
export const useGetFollowerLeaderboard = createUseFetch<FollowerLeaderboard, LeaderboardParams>({
  key: 'follower-leaderboard',
  path: '/recommendation/leaderboards/follower-value'
})

export type LikedLeaderboardUser = {
  user: HydratedUserProfile
  rank: {
    period_change_value: number
    like_value: number
    reference_value: number
    period_change_percent: number | null
    position: number
  }
}
export type LikedLeaderboard = {
  users: LikedLeaderboardUser[]
}
export const useGetLikedLeaderboard = createUseFetch<LikedLeaderboard, LeaderboardParams>({
  key: 'liked-leaderboard',
  path: '/recommendation/leaderboards/like-value/profiles'
})

export type TrendingAsset = {
  count: number
  name: string
  short_name: string
}

export const useTrendingAssets = createUseFetch<TrendingAsset[]>({
  key: 'trending-assets',
  path: `/mds/most-viewed-assets`,
  isPublic: true
})

export const useGetCachedAssetChartData = cacheLast(useGetAssetChartData)

export type AssetInvestors = {
  user_uuid: string
  picture: string
  name: string
  username: string
}

export type AssetInvestorsAndOrders = {
  buys: string
  sells: string
  calls: string
  puts: string
  investors_who_bought: AssetInvestors[]
  investors_who_sold: AssetInvestors[]
  call_investors: AssetInvestors[]
  put_investors: AssetInvestors[]
}

type AssetInvestorsParams = {
  query: {
    symbol: string
    period: string
  }
}

export const ASSET_INVESTORS_PATH = `/pds/orders/aggregate`
export const useGetAssetInvestors = createUseFetch<AssetInvestorsAndOrders, AssetInvestorsParams>({
  key: 'asset-investors-and-orders',
  path: ASSET_INVESTORS_PATH
})
