import React, { useCallback } from 'react'
import { ContentSearch } from '@commonstock/common/src/api/recommendation'
import { SearchItem } from 'src/scopes/search/SearchInput'
import { Formatter, searchMarkdown } from 'src/scopes/text-editor/csMarkdown'

type Props = {
  active: boolean
  post: ContentSearch
  onMouseEnter: () => any
  onSelect: (post: ContentSearch) => void
  inPage?: boolean
}

const PostResult = ({ active, post, onMouseEnter, onSelect, inPage }: Props) => {
  const onClick = useCallback(() => {
    onSelect(post)
  }, [onSelect, post])

  return (
    <SearchItem active={active} onClick={onClick} onMouseEnter={onMouseEnter} inPage={!!inPage}>
      <Formatter clampLine={1} processor={searchMarkdown} text={post.content} />
    </SearchItem>
  )
}

export default PostResult
