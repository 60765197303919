import React from 'react'
import { Invite } from '../../theme/NewIcons'
import { PrimaryButton } from '../../composing/CSButton'
import { Spacing } from '../../composing/Spacing'

type Props = {
  onClick: () => void
  title: string
}

function InviteButton({ onClick, title }: Props) {
  return (
    <PrimaryButton onClick={onClick} aria-label={title}>
      <Spacing margin={[0, 0.25, 0, 0]}>
        <Invite />
      </Spacing>
      {title}
    </PrimaryButton>
  )
}

export default InviteButton
