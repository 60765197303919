import React from 'react'
import { Formatter } from '../text-editor/csMarkdown'
import PostPreview from './PostPreview'
import { PostFeedItem } from '@commonstock/common/src/api/feed'
import { destructPostFeedItem } from './utils'

type Props = {
  postFeedItem: PostFeedItem
  preview?: boolean
  isPublic?: boolean
  hideImages?: boolean
}

function PostView({ postFeedItem, preview, isPublic, hideImages }: Props) {
  let { post, mentionAttachments } = destructPostFeedItem(postFeedItem)

  if (!post) return null
  // @TODO this is maybe not the most elegant solution, but sometimes BE returns summary and no body (feed)
  let markdownText = post.body || post.summary

  return preview ? (
    <PostPreview postFeedItem={postFeedItem} isPublic={isPublic} hideImages={hideImages} />
  ) : (
    <Formatter text={markdownText} mentions={mentionAttachments} />
  )
}

export default React.memo(PostView)
