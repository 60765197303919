import { HookOptions } from '../react/createUseFetch'
import { ReturnTuple } from '../constants'
import { useEffect, useRef, useState } from 'react'

export default function throttleUseFetch<Payload, Params>(
  useFetch: (params: Params, hookOptions?: HookOptions) => ReturnTuple<Payload, Params>,
  wait: number
) {
  return (params: Params, hookOptions: HookOptions = {}) => {
    let [paused, setPaused] = useState(true)
    let intervalRef = useRef<ReturnType<typeof setTimeout>>()
    let currentParams = useRef<Params>(params)
    currentParams.current = params
    let useParamsRef = useRef<Params>(params)

    useEffect(() => {
      if (intervalRef.current) return setPaused(true)
      intervalRef.current = setTimeout(() => {
        // store current params and unpause
        useParamsRef.current = currentParams.current
        setPaused(false)
        intervalRef.current = undefined
      }, wait)
    }, [params])
    return useFetch(params, { paused, ...hookOptions })
  }
}
