import { track } from '../mixpanel'

export enum SearchType {
  Asset = 'Asset',
  Post = 'Post',
  User = 'User',
  TopInvestor = 'Top Investor',
  TrendingAsset = 'Trending Asset',
  Text = 'Text'
}

/*
 * Values:
 *   Asset = "{symbol}:{type}""
 *   User = "{user_uuid}"
 *   Text = search text
 */
export function searchTap(type: SearchType, value: string) {
  track('Search:Tap', {
    type: type,
    value: value
  })
}
