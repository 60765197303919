import React, { useCallback, useMemo } from 'react';
import SelectUserInput from '../chat/SelectUserInput';
import SelectUserItem from './SelectUserItem';
import { useQueryVisibleProfiles, HydratedUserProfile, BasicProfileToHydratedProfile, useFollowing, useGetMyProfile, UserProfile, UserProfileType } from '@commonstock/common/src/api/profile';
import { useChannels } from '../chat/ChannelContext';
import throttleUseFetch from '@commonstock/client/src/utils/throttleUseFetch';
import cacheLast from '@commonstock/client/src/utils/cacheLast';
import { Member } from 'sendbird';
import { TextSubheadEmphasis, TextDisplaySmall, TextCaption } from '../../composing/CSText';
import { Expander, FlexRow } from '../../composing/Flex';
import { SpacerH, Spacing } from '../../composing/Spacing';
import LoadingListItem from '../../components/LoadingListItem';
import { Colors } from '../../theme/constants';
import { css, cx } from '@linaria/core';
import { scrollY } from '../../theme/AtomicClasses';
import { AddGroup, ChevronRight } from '../../theme/NewIcons';
import { useEphemeralModal } from '../modal/Modal';
import dynamic from 'next/dynamic';
import { Pager } from '@commonstock/client/src/react/createUsePagedFetch';
import useScrollSentinel from 'src/utils/useScrollSentinel';
import { ScrollerClass } from '../nav/StandardScreen';
const NewChannel = dynamic(() => import('./NewChannel'));
const useQueryProfiles = cacheLast(throttleUseFetch(useQueryVisibleProfiles, 1000));
type ChannelSelectMembersProps = {
  currentUserId: string;
  selectedProfiles: HydratedUserProfile[];
  setSelectedProfiles: React.Dispatch<React.SetStateAction<HydratedUserProfile[]>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  externalInvite?: boolean;
  hideGroupButton?: boolean;
  isNew?: boolean;
};

function ChannelSelectMembers({
  externalInvite,
  currentUserId,
  selectedProfiles,
  setSelectedProfiles,
  search,
  setSearch,
  hideGroupButton,
  isNew
}: ChannelSelectMembersProps) {
  // Backend doesnt handle the empty search case so lets ignore it
  let [searchProfiles,, searchLoading] = useQueryProfiles({
    meta: {
      search
    }
  }, {
    paused: !search
  });
  if (!search) searchProfiles = [];
  let [myProfile] = useGetMyProfile();
  let [myFollowingPages = [],,, pager] = useFollowing({
    meta: {
      user_uuid: myProfile?.user_uuid || 'n/a'
    }
  }, {
    paused: !myProfile
  });
  let myFollowing = myFollowingPages.flatMap(page => page);
  let following: HydratedUserProfile[] | undefined = myFollowing?.map(p => ({ ...p,
    uuid: p.user_uuid
  }));
  const {
    currentChannel
  } = useChannels();
  const members = useMemo(() => isNew ? [] : currentChannel?.members, [currentChannel, isNew]);
  const onSelectUser = useCallback((profile: HydratedUserProfile) => {
    const filteredProfiles = selectedProfiles.filter(p => p.uuid !== profile.uuid);
    setSelectedProfiles(prev => prev.length !== filteredProfiles.length ? filteredProfiles : [...prev, profile]);
  }, [selectedProfiles, setSelectedProfiles]);
  const [followingProfiles, notFollowingProfiles] = useMemo(() => {
    if (!search || !following) return [undefined, undefined];
    const followingIds = following.map(u => u.uuid);
    let map: {
      following: HydratedUserProfile[];
      notFollowing: HydratedUserProfile[];
    } = {
      following: [],
      notFollowing: []
    };
    searchProfiles?.forEach(p => {
      if (followingIds.includes(p.user_uuid)) map.following.push(BasicProfileToHydratedProfile(p));else map.notFollowing.push(BasicProfileToHydratedProfile(p));
    });
    return [map.following, map.notFollowing];
  }, [following, search, searchProfiles]);
  const notFollowingSelected = useMemo(() => selectedProfiles.filter(selectedProfile => following?.every(u => u.uuid !== selectedProfile.uuid)), [following, selectedProfiles]);
  const modal = useEphemeralModal();
  const onClickNewGroup = useCallback(() => modal(<NewChannel />), [modal]);
  return <div className={cx(bodyClass, scrollY, ScrollerClass)}>
      <SelectUserInput search={search} setSearch={setSearch} externalInvite={externalInvite} />
      {!hideGroupButton && <CreateGroup onClick={onClickNewGroup} />}
      <UserItemGroup heading="FOLLOWING" profiles={search ? followingProfiles : following} members={members} selectedProfiles={selectedProfiles} currentUserId={currentUserId} onSelect={onSelectUser} emptyText="No following user found" pager={pager} />
      {Boolean(search || notFollowingSelected.length) && <UserItemGroup heading="NOT FOLLOWING" profiles={search ? notFollowingProfiles : notFollowingSelected} members={members} selectedProfiles={selectedProfiles} currentUserId={currentUserId} onSelect={onSelectUser} emptyText="No following user selected" />}
      {search && searchLoading && <LoadingListItem />}
    </div>;
}

type UserItemGroupProps = {
  heading?: string;
  profiles: HydratedUserProfile[] | undefined;
  selectedProfiles: HydratedUserProfile[];
  members?: Member[];
  currentUserId: string;
  onSelect: (profile: HydratedUserProfile) => void;
  emptyText: string;
  pager?: Pager<UserProfile[], any>;
};

function UserItemGroup({
  heading,
  profiles,
  selectedProfiles,
  members,
  currentUserId,
  onSelect,
  emptyText,
  pager
}: UserItemGroupProps) {
  const filteredProfiles = useMemo(() => profiles?.filter(p => p.uuid !== currentUserId && p.type === UserProfileType.User), [currentUserId, profiles]);
  const sentinelRef = useScrollSentinel(pager?.next);
  if (!profiles) return null;
  return <div className={userItemGroupClass}>
      {heading && <Spacing padding={[0.75, 0, 0]}>
          <TextSubheadEmphasis color={Colors.TextSecondary}>{heading}</TextSubheadEmphasis>
        </Spacing>}
      {!filteredProfiles && <LoadingListItem />}
      {filteredProfiles?.map(profile => <SelectUserItem key={profile.uuid} isSelected={Boolean(selectedProfiles.find(p => p.uuid === profile.uuid))} memberStatus={members?.find(m => m.userId === profile.uuid)?.state} onSelect={onSelect} profile={profile} isOperator={true} unlinked />)}
      {emptyText && filteredProfiles && !filteredProfiles.length && <Spacing margin={[1, 'auto']}>
          <TextCaption block color={Colors.TextSecondary}>
            {emptyText}
          </TextCaption>
        </Spacing>}
      <div ref={sentinelRef} />
    </div>;
}

function CreateGroup({
  onClick
}: {
  onClick: () => void;
}) {
  return <FlexRow alignCenter className={createGroupClass} onClick={onClick}>
      <AddGroup size={2} />
      <SpacerH rem={1} />
      <TextDisplaySmall>Create a New Group</TextDisplaySmall>
      <Expander />
      <ChevronRight color={Colors.TextSecondary} size={1.5} />
    </FlexRow>;
}

export default ChannelSelectMembers;
const bodyClass = "b53208l";
const createGroupClass = "c12jlel9";
const userItemGroupClass = "u1sdypwc";

require("../../../.linaria-cache/packages/oxcart/src/scopes/chat-edit/ChannelSelectMembers.linaria.module.css");