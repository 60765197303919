import React, { useEffect } from 'react'
import { DetailCard, PaneColumn, PanelGrid } from '../../components/styles'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing } from '../../composing/Spacing'
import { useGetPost } from '@commonstock/common/src/api/post'
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment'
import { PostContent } from './PostCard'
import { useAuth } from '../auth/AuthContext'
import { cx } from '@linaria/core'
import RootComments from '../comments/RootComments'
import CommentItem from '../comments/CommentItem'
import { useTrackPostDetailImpression } from '../analytics/events.v1'
import { PostFeedItem } from '@commonstock/common/src/api/feed'
import { useRouter } from 'next/router'
import NotFound from '../../components/NotFound'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { DetailContentWrapper } from '../content/DetailContentWrapper'
import RelatedContent from 'src/scopes/discover/RelatedContent'
import { CardLayoutTypes } from 'src/scopes/feed/constants'
import PrivateContentCard from 'src/scopes/profile/PrivateContentCard'
import PostTwitterShare, { SHARE_POST_QUERY } from './PostTwitterShare'
import { useUser } from 'src/scopes/auth/AuthContext'
import { useEphemeralModal } from 'src/scopes/modal/Modal'
import { Routes } from 'src/scopes/nav/constants'
import { ColumnSizeTypes } from 'src/scopes/layout/constants'
import { PostDetailScreenProps } from 'pages/post/[postId]'

type Params = { postId: string; commentId?: string }
type Props = PostDetailScreenProps & {
  isModal?: boolean
  uuid?: string
  cardLayout: CardLayoutTypes
  columnSize?: ColumnSizeTypes
}
function PostDetail({ isModal, postFeedItem: _postFeedItem, privateProfile, uuid, cardLayout, columnSize }: Props) {
  const router = useRouter()
  const modal = useEphemeralModal()
  const { commentId, postId } = router.query as Params
  const { isModerator } = useAuth()
  const [myProfile] = useUser()

  const postUuid = uuid || postId || _postFeedItem?.uuid || ''

  const [postFeedItem = _postFeedItem, fail] = useGetPost(
    { meta: { uuid: postUuid } },
    { paused: (!isLoggedIn() && !!privateProfile) || !postUuid }
  )

  const [fetchComment, failComment] = useGetComment({ meta: { uuid: commentId || '' } }, { paused: !commentId })
  const rootUserUuid = postFeedItem?.user_uuid || ''

  useTrackPostDetailImpression(postFeedItem)

  useEffect(() => {
    const hasTwitterAuth = myProfile?.external_providers.some(p => p.canonical === 'twitter' && !p.is_stale)

    if (!isModal && hasTwitterAuth && router.query[SHARE_POST_QUERY] && postUuid) {
      modal(<PostTwitterShare uuid={postUuid} />, { onClose: () => router.replace(Routes.postDetail(postUuid)) })
    }
  }, [postUuid, myProfile, isModal, router, modal])

  if ((!isModal && (fail || failComment)) || (!isLoggedIn() && privateProfile)) {
    return (
      <PanelGrid className={columnSize}>
        <PaneColumn>
          {privateProfile ? (
            <PrivateContentCard
              user={privateProfile}
              title="This post is from a private account."
              subtitle={`Follow ${privateProfile.name} on Commonstock to view this post.`}
            />
          ) : (
            <NotFound />
          )}
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModal && fail) {
    return (
      <DetailCard className={cx(isModal && 'modal')}>
        <NotFound />
      </DetailCard>
    )
  }
  if (!postFeedItem || (commentId && !fetchComment)) {
    if (isModal) {
      return (
        <DetailCard className={cx(isModal && 'modal', 'loading')}>
          <BrandSpinner />
        </DetailCard>
      )
    }
    return (
      <PanelGrid className={columnSize}>
        <PaneColumn>
          <DetailCard className={cx(isModal && 'modal', 'loading')}>
            <BrandSpinner />
          </DetailCard>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }
  if (isModerator && fetchComment?.archived_at) {
    return (
      <PanelGrid className={columnSize}>
        <PaneColumn>
          <Spacing padding={[0, 1, 1]}>
            <DetailCard>
              <CommentItem
                comment={fetchComment}
                rootType={ParentTypes.POST}
                rootUuid={postUuid}
                rootUserUuid={rootUserUuid}
                cardLayout={cardLayout}
              />
            </DetailCard>
          </Spacing>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>
    )
  }

  if (!isModal) {
    return (
      <PanelGrid className={columnSize}>
        <PaneColumn>
          <PostPageContent
            postFeedItem={postFeedItem}
            postUuid={postUuid}
            rootUserUuid={rootUserUuid}
            cardLayout={cardLayout}
          />
        </PaneColumn>
        <PaneColumn>
          <RelatedContent feedItem={postFeedItem} />
        </PaneColumn>
      </PanelGrid>
    )
  }

  return (
    <PostPageContent
      isModal
      postFeedItem={postFeedItem}
      postUuid={postUuid}
      rootUserUuid={rootUserUuid}
      cardLayout={cardLayout}
    />
  )
}

type PostMainContentProps = {
  isModal?: boolean
  postFeedItem: PostFeedItem
  postUuid: string
  rootUserUuid: string
  cardLayout: CardLayoutTypes
}
function PostPageContent({ isModal, postFeedItem, postUuid, rootUserUuid, cardLayout }: PostMainContentProps) {
  const initialComments = postFeedItem?.resources.memos[postFeedItem.uuid]?.comments

  return (
    <DetailContentWrapper feedItem={postFeedItem} isModal={isModal} cardLayout={cardLayout}>
      <PostContent postFeedItem={postFeedItem} detail cardLayout={cardLayout} />
      <RootComments
        initialComments={initialComments}
        uuid={postUuid}
        type={ParentTypes.POST}
        isModal={!!isModal}
        rootUserUuid={rootUserUuid}
        cardLayout={cardLayout}
      />
    </DetailContentWrapper>
  )
}

export default PostDetail
