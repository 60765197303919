import { useGetAssetProfileAction } from '@commonstock/common/src/api/asset-profile'
import { useGetUsernameUuidAction } from '@commonstock/common/src/api/profile'
import { AssetType } from '@commonstock/common/src/types'
import { AssetMention, MentionAttachments, ProfileMention } from '@commonstock/common/src/types/mentions'
import { cx } from '@linaria/core'
import { RefObject, useCallback } from 'react'
// @ts-ignore
import TurndownService from 'turndown'
import { MarkdownLinkStyles, emojiClass } from '../../components/styles'
import { captureException } from '../../dev/sentry'
import { HelperType, HelperPayload } from './TextEditorContext'
import { linkClass } from '../../theme/AtomicClasses'
import { getLinks } from '../post/utils'

// temporary disallow escaping because iOS does not yet support it
TurndownService.prototype.escape = (text: string) => text

let turndownService = new TurndownService()
let absoluteLinkRule = {
  filter: ['a'],
  replacement: (content: string, node: HTMLAnchorElement) => {
    const isMention =
      (node.classList.contains('asset-mention') && content.startsWith('$')) ||
      (node.classList.contains('user-mention') && content.startsWith('@'))
    // @NOTE: we want to leave these as links, but need to strip link for now to accomodate iOS
    // @TODO: identify both @$ and class
    if (isMention) return content
    return `[${content}](${node.href})`
  }
}
// rule to make sure we never convert to relative links
turndownService.addRule('absolute-links', absoluteLinkRule)

// Used in places where we should not support markdown, like chat or posts
let turndownServiceSimplified = new TurndownService()
turndownServiceSimplified.addRule('disable chat rules', {
  filter: ['b', 'i', 'a', 'li', 'ul'],
  replacement: (content: string) => {
    return content
  }
})

export function getTextNodesIn(el: HTMLElement | Text | Node) {
  const textNodes: Text[] = []
  if (el.nodeType === 3) {
    textNodes.push(el as Text)
  } else {
    const children = el.childNodes
    for (let i in children) {
      textNodes.push(...getTextNodesIn(children[i]))
    }
  }
  return textNodes
}

function normalizeList(elm: Element) {
  let children = elm.children
  for (let i = 0; i < children.length; ++i) {
    let currentChild = children[i]
    let brs = currentChild.querySelectorAll(':scope > br')
    brs.forEach(br => br && br.remove())
    !currentChild.textContent?.trim() && currentChild.remove()
    let $prevChild = children[i - 1]
    if (/^UL|OL$/.test(currentChild.tagName)) {
      try {
        $prevChild.appendChild(currentChild)
      } catch (e) {
        console.warn(e)
      }
      normalizeList(currentChild)
    }
  }
}

function normalizeTargetHtml(el: HTMLElement) {
  const target = el.cloneNode(true) as HTMLElement

  target.innerHTML = target.innerHTML
    // Literal new lines are ignored by turndown
    .replace(/\n/g, '<br>')
    // Every time a line break is wrapped by bold/italic it gets ignored by turndow, so we clean it out
    .replace(/<(?:strong|em|b|i)><br\/?><\/(?:strong|em|b|i)>/g, '<br>')
    // Call it twice for situations where we have bold + iatlic texts
    .replace(/<(?:strong|em|b|i)><br\/?><\/(?:strong|em|b|i)>/g, '<br>')

  // @NOTE: transforms back emoji to text before saving
  const emojiImgs = target.querySelectorAll(`img.${emojiClass}`) as NodeListOf<HTMLImageElement>
  emojiImgs.forEach(img => img.alt && img.replaceWith(document.createTextNode(img.alt)))

  // Remove SVG images, since failed images has a svg placeholder and IOS can't handle svgs
  const svgImages = target.querySelectorAll('img[src$="svg"]') as NodeListOf<HTMLImageElement>
  svgImages.forEach(el => el.remove())
  const failedImages = target.querySelectorAll('.failed-image') as NodeListOf<HTMLImageElement>
  failedImages.forEach(el => el.remove())

  // Replace links applied to empty lines
  const links = target.querySelectorAll('a')
  links.forEach(el => {
    if (el.innerHTML === '<br>') return el.replaceWith(document.createElement('br'))
    else if (!el.innerHTML.trim()) {
      if (el.parentElement?.textContent?.trim() === '') return el.replaceWith(document.createElement('br'))
      return (el.outerHTML = `<span>${el.innerHTML}</span>`)
    }
  })

  target.normalize()

  const nodes = getTextNodesIn(target)
  for (let i in nodes) {
    // @NOTE: Convert white space to &nbsp; before getting markdown, since turndown ignores multiple spaces
    if (nodes[i].textContent) nodes[i].textContent = nodes[i].textContent?.replace(/ /g, '\u00a0') || ''
  }

  const lists = target.querySelectorAll('ul, ol')
  for (let i = 0; i < lists.length; ++i) {
    normalizeList(lists[i])
  }

  return target
}

const endSpaceRe = new RegExp('\u00a0', 'g')
export function convertHtmlToMarkdown({
  target,
  plaintext,
  returnPlaintext
}: {
  target: HTMLDivElement | null
  plaintext?: boolean
  returnPlaintext?: boolean
}) {
  try {
    if (!target) return ''
    const service = plaintext ? turndownServiceSimplified : turndownService
    const normalizedTarget = normalizeTargetHtml(target)

    // convert to markdown, and turn any nbsp into plain spaces for BE's regex simplicity
    let result = service.turndown(normalizedTarget).replace(endSpaceRe, ' ')
    let cleanedResult = ''

    // @NOTE: remove double newline characters
    cleanedResult = result.replace(/\n\n/g, '\n')

    // @NOTE: Cleanup list markdown
    // @TODO: Maybe remove this prop, has this only affects lists
    if (!returnPlaintext) {
      cleanedResult = cleanedResult
        // ios has a bug with lists starting with `\n* `
        .replace(/\n\* |^\* /g, '\n- ')
        // Turndown creates unordered list items with 3 spaces `\n*   text`
        .replace(/\n-\s{3}/g, '\n- ')
        // Turndown creates ordered list items with 2 spaces `\n*   text`
        .replace(/(\n\d+\.)\s{2}/g, '$1 ')
        // Cleanup empty list item line
        // @TODO: Find out if this is still needed
        .replace(/\n-\s*(:?\n|$)/g, '\n')
    }

    // Cleanup right witespace for every line, and starting/ending empty lines
    const lines = cleanedResult.trimRight().split('\n')
    let startedContent = null
    for (let i in lines) {
      lines[i] = lines[i].trimRight()
      if (startedContent === null && lines[i]) startedContent = +i
    }
    cleanedResult = lines.slice(startedContent || 0, lines.length).join('\n')

    return cleanedResult
  } catch (error) {
    console.error('## Couldnt get markdown', error)
    return ''
  }
}

export function preHydrateText(text: string) {
  const arrayRanges = Array.from(text.matchAll(/\[.*?\]\(.*?\)/gm))
    .map(m => m?.index !== undefined && [m.index, m.index + m[0].length])
    .filter(Boolean) as Array<[number, number]>

  let user_mentions_map: { [key: string]: ProfileMention } = {}
  let profileRe = new RegExp(/@([\w\d_\-.]+)( |\W|$)/, 'g')

  const profileMatches = Array.from(text.matchAll(profileRe))
  profileMatches.forEach(m => {
    if (arrayRanges.some(range => m.index && m.index > range[0] && m.index < range[1])) return
    const capture = m[1]
    user_mentions_map[capture] = {
      username: capture,
      // dummy values to satisfy the type and ensure no downstream breakage
      picture: '',
      name: '',
      verified: false,
      private: false,
      uuid: capture
    }
  })

  let asset_mentions_map: { [key: string]: AssetMention } = {}
  let assetRe = new RegExp(/\$([\w\d_]+(:?\.[xX])?)( |\W|$)/, 'g')
  const assetMatches = Array.from(text.matchAll(assetRe))
  assetMatches.forEach(m => {
    if (arrayRanges.some(range => m.index && m.index > range[0] && m.index < range[1])) return
    const capture = m[1]
    const [symbol, type]: string[] = capture.split('.')
    asset_mentions_map[symbol] = {
      symbol,
      type: type ? AssetType.crypto : AssetType.equity,
      // dummy values to satisfy the type and ensure no downstream breakage
      name: '',
      short_name: '',
      current_price: 0,
      change_percentage: 0,
      id: 0,
      change_value: 0,
      currency: ''
    }
  })

  const mentions: MentionAttachments = {
    user_mentions: Object.values(user_mentions_map),
    asset_mentions: Object.values(asset_mentions_map)
  }
  return { text, mentions }
}

export function maybeUnwrapLinkedMention(target: HTMLDivElement, range: Range) {
  const lastPos = getPos(target)
  const selection = document.getSelection()
  let parent = range?.startContainer?.parentElement
  let originalText = parent?.getAttribute('data-original')
  let textContent = parent?.textContent
  if (
    selection &&
    lastPos !== null &&
    textContent &&
    originalText &&
    parent?.tagName === 'A' &&
    originalText !== textContent
  ) {
    const range = new Range()
    range.setStartBefore(parent)
    range.setEndAfter(parent)
    selection.removeAllRanges()
    selection.addRange(range)
    document.execCommand('unlink')
    setPositionAt(target, lastPos)
  }
}

export function getRangeOrEnd(el: HTMLElement | HTMLDivElement | null, toStart?: boolean, forceReset?: boolean) {
  let rangeInTarget = getRangeInTarget(el)
  if (!rangeInTarget || forceReset) {
    rangeInTarget = document.createRange()
    el && rangeInTarget.selectNodeContents(el)
    if (toStart) {
      rangeInTarget.collapse(true)
    } else {
      rangeInTarget.collapse()
    }
  }

  return rangeInTarget
}

export function getRangeInTarget(el: HTMLElement | HTMLDivElement | null) {
  let selection = document.getSelection()
  let isInSelection = el && selection?.containsNode(el, true)

  let rangeInTarget = isInSelection && selection && selection.rangeCount > 0 && selection.getRangeAt(0).cloneRange()
  return rangeInTarget
}

export function resetSelectionRange(range?: Range) {
  if (!range) return
  let selection = document.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)
}

export const removeLink = (
  targetRef: RefObject<HTMLDivElement>,
  markdown: string,
  previewLink: string,
  focus: () => any
) => {
  if (targetRef.current) {
    const range = document.createRange()
    const sel = window.getSelection()
    let pos = markdown.lastIndexOf(previewLink)

    if (pos !== -1) {
      targetRef.current.innerHTML = markdown.substring(0, pos) + markdown.substring(pos + previewLink.length + 1)
      if (targetRef.current.childNodes.length > 0) {
        range.setStart(targetRef.current.childNodes[0], pos)
        range.collapse(true)
        if (sel) {
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }
    } else {
      focus()
    }
  }
  return targetRef.current?.innerText || ''
}

// this logic relies on the mention element to always have a element wrapper (in this case span or a)
export function getCaretPosition(
  target: HTMLElement | null,
  mentionWrapper: HTMLElement | null,
  leftText: string | false
) {
  if (!target) return null
  const pos = getPos(target)
  const selection = document.getSelection()
  const isFocused = selection?.containsNode(target, true)

  try {
    if (pos !== null && selection && leftText && isFocused) {
      const range = getRangeAt(target, pos - leftText.length)
      const rangeBox = range.getBoundingClientRect()
      const mentionBox = mentionWrapper?.getBoundingClientRect()

      const bottomOffset = window.innerHeight - rangeBox.top
      // This is based on current item line height,
      // tought on adding it dynamically based on ref clientHeight,
      // but it could make this position jump from top to bottom when loading results
      const maxResultsHeight = 5 * 40
      const topPositioned = rangeBox.top > maxResultsHeight
      const bottom = topPositioned ? bottomOffset : 'auto'
      const top = topPositioned ? 'auto' : rangeBox.bottom
      let offsetLeft = rangeBox.left

      if (mentionBox && rangeBox.left + mentionBox.width > window.innerWidth) {
        return { right: 10, bottom, top }
      }

      return { left: offsetLeft, bottom, top }
    }
  } catch (error) {
    console.error(error)
  }
  return null
}

export function modifySelection(
  selection: Selection,
  alter: 'extend' | 'move',
  direction: 'right' | 'left',
  granularity: 'word' | 'character' | 'line'
) {
  try {
    // @ts-ignore
    selection.modify(alter, direction, granularity)
  } catch (error) {
    captureException(error, {
      selection,
      alter,
      direction,
      granularity
    })
  }
}

function reverseString(str: string) {
  return str
    .split('')
    .reverse()
    .join('')
}

export type MentionDetected = {
  type: HelperType | false
  range?: Range
  search?: string
  leftText?: string
  isEndOfLine?: boolean
}
const helperTypes = Object.values(HelperType)
export function mentionDetect(targetEl: HTMLDivElement | null): MentionDetected {
  if (!targetEl) throw new Error('mentionDetect missing target')
  let selection = window.getSelection()
  const range = getRangeInTarget(targetEl) || undefined
  const defaultResponse: MentionDetected = {
    type: false,
    range
  }
  if (document.activeElement !== targetEl || !selection || !range) return defaultResponse

  // If current selection has whitespaces we avoid mentioning because it can make undesired replaces
  if (/ /g.test(selection.toString())) return defaultResponse

  const endContainer = range.endContainer
  const parentLineElement =
    range.endContainer.nodeType === 3 ? range.endContainer.parentElement?.closest('p, div') : range.endContainer
  if (!parentLineElement) return defaultResponse
  const nodes = getTextNodesIn(parentLineElement)
  const currentTextIndex = nodes.indexOf(endContainer as Text)
  // If the current node is already a link, mention should not show
  if (nodes[currentTextIndex]?.parentElement?.nodeName === 'A') return defaultResponse
  // Get left text by joining all nodes before current endContainer
  const leftNodesText = nodes.slice(0, currentTextIndex).reduce((acc, n) => `${acc}${n.textContent}`, '') || ''
  const currentNodeLeftText = endContainer.textContent?.substr(0, range.endOffset) || ''
  const leftString = `${leftNodesText}${currentNodeLeftText}`

  // Reverse identify current position until a HelperType or white space
  const matchLeft = reverseString(leftString).match(/^(?:[xX]?\.?\w*\$|[\w_\-.]*@|.*?\s)/g)

  // Rejoin text and normalize it
  const leftText = Array.isArray(matchLeft) ? reverseString(matchLeft[0]) : null
  const hasMentionType = leftText ? helperTypes.includes(leftText[0] as HelperType) : false

  let isEndOfLine = false
  let searchText: string | undefined

  // If left text can be trimmed, it means caret is after a whitespace char
  // which means it is not on current mention text
  if (hasMentionType && leftText && leftText.length === leftText.trim().length) {
    // get text at right to identify the rest of the word, if it doesnt have a subsequent whitespace char
    const rightNodesText = nodes.slice(currentTextIndex + 1).reduce((acc, n) => `${acc}${n.textContent}`, '') || ''
    const currentNodeRightText = endContainer.textContent?.substr(range.endOffset) || ''
    const rightString = `${currentNodeRightText}${rightNodesText}`

    // Forward identify rest of text until a non text character optionally followed by .x or .X
    const matchRight = rightString.match(/^([xX]|\.[xX]|\w+(?:\.[xX])?)/g)
    isEndOfLine = matchRight === null && !rightString.trim()
    searchText = `${leftText}${Array.isArray(matchRight) ? matchRight[0] : ''}`
  }
  // const isMention = leftText && searchText?.match(/^(?:@|\$)(?:\w+\.[xX]|\w*)$/)
  const isAssetMention = leftText && searchText?.match(/^\$(?:\w+\.[xX]|\w*)$/)
  const isProfileMention = leftText && searchText?.match(/^@[\w\d_\-.]*$/)
  if ((isAssetMention || isProfileMention) && leftText && searchText) {
    return {
      type: searchText[0] as HelperType,
      search: (searchText.slice(1, searchText.length) || '').toLowerCase(),
      leftText: leftText,
      range: selection.getRangeAt(0),
      isEndOfLine
    }
  }
  return defaultResponse
}

export function useUpdateLinkedMentions() {
  const getAssetProfile = useGetAssetProfileAction()
  const getUsernameUuid = useGetUsernameUuidAction()

  const updateLinkedAssets = useCallback(
    async (target?: HTMLAnchorElement | null) => {
      if (target && target.classList.contains('asset-mention-pending') === false) return
      const el = target || (document.querySelector('a.asset-mention-pending') as HTMLAnchorElement | null)
      if (el?.classList.contains('asset-mention') === false) return
      el?.classList.remove('asset-mention-pending')

      if (!el) return
      if (el.dataset.type && el.dataset.symbol) {
        const { symbol, type } = el.dataset
        await getAssetProfile({ meta: { type: type, symbol: symbol } })
          .then(r => {
            let change = r.success?.payload.quote.changePercent || 0
            if (el) {
              if (change > 0) el.classList.add('positive')
              if (change < 0) el.classList.add('negative')
            }
          })
          .catch(() => {
            // Remove link if failed to fetch asset info data
            el.replaceWith(...Array.from(el.childNodes))
          })
        updateLinkedAssets()
      }
    },
    [getAssetProfile]
  )

  const updateLinkedProfiles = useCallback(
    async (target?: HTMLAnchorElement | null) => {
      if (target && target.classList.contains('user-mention-pending') === false) return
      const el = target || (document.querySelector('a.user-mention-pending') as HTMLAnchorElement | null)
      if (el?.classList.contains('user-mention') === false) return
      el?.classList.remove('user-mention-pending')
      const username = el?.dataset.original?.replace('@', '')

      if (!el) return
      if (username) {
        await getUsernameUuid({ meta: { username } })
          .then(() => {
            // noop
          })
          .catch(() => {
            // Remove link if failed to fetch asset info data
            el.replaceWith(...Array.from(el.childNodes))
          })
        updateLinkedProfiles()
      }
    },
    [getUsernameUuid]
  )

  const updateLinks = useCallback(
    async (target?: HTMLAnchorElement | null) => {
      updateLinkedAssets(target)
      updateLinkedProfiles(target)
    },
    [updateLinkedAssets, updateLinkedProfiles]
  )

  return updateLinks
}

export function addMentionToElement(
  inputEl: HTMLDivElement,
  link: string,
  text: string,
  payload: HelperPayload,
  mentionData: MentionDetected,
  preventSpace: boolean,
  cb?: () => void
) {
  // @TODO perhaps this logic can be abstracted out somewhere else and shared with CSMarkdownLink
  // also would be nice to pull asset profile for mentions context
  let el = document.createElement('A')
  el.setAttribute('data-original', text)
  inputEl.classList.add('mentioning')
  if (payload.type === HelperType.Asset) {
    const { symbol, type } = payload.asset
    el.className = cx(MarkdownLinkStyles, 'linkDestyle', 'asset-mention', 'asset-mention-pending')
    el.dataset.symbol = symbol
    el.dataset.type = type
  } else if (payload.type === HelperType.User) {
    el.className = cx(MarkdownLinkStyles, 'linkDestyle', 'user-mention', 'user-mention-pending')
    el.dataset.uuid = payload.profile.uuid
  }

  const { type, range, search, leftText, isEndOfLine } = mentionData
  if (!type || !leftText) return
  let parentNode = range?.startContainer?.parentNode
  let selection = document.getSelection()
  if (parentNode && range && selection) {
    selection.collapseToEnd()

    // Use a move count to prevent infinite loop
    let moveCount = 0
    do {
      moveCount++
      // Select left side of the mention
      // Word bundary ignores special characters as [$@] so we need to do an extra step
      modifySelection(
        selection,
        'extend',
        'left',
        // Word bundary ignores special characters as [$@] so we need to do an character step to match it
        leftText.length - selection.toString().length > 1 ? 'word' : 'character'
      )
    } while (leftText.length > selection.toString().length && moveCount < 4)

    moveCount = 0
    const fullMentionText = `${type}${search}`.toLowerCase()
    const isCaretAtTheEndOfMention = fullMentionText === leftText.toLowerCase()
    while (fullMentionText.length > selection.toString().length && moveCount < 4) {
      if (moveCount === 0) selection.collapseToStart()
      // Move cursor to the end of the current word
      modifySelection(selection, 'extend', 'right', 'word')
      moveCount++
    }

    // TODO: think about a way to track this error to fix later and discover why
    if (selection.toString().toLowerCase() !== fullMentionText) return

    el.setAttribute('href', link)
    el.textContent = text
    let wrapper = document.createElement('span')
    wrapper.appendChild(el)
    document.execCommand(
      'insertHtml',
      false,
      `${wrapper.innerHTML}${!preventSpace && isEndOfLine && isCaretAtTheEndOfMention ? ' ' : ''}`
    )

    setTimeout(() => inputEl && inputEl.classList.remove('mentioning'), 200)
    cb && cb()
  }
}

function rangeSelectsSingleNode(range: Range) {
  const startNode = range.startContainer
  return startNode === range.endContainer && startNode.hasChildNodes() && range.endOffset === range.startOffset + 1
}

function getSelectedParentElement(range: Range) {
  // Selection encompasses a single element
  if (rangeSelectsSingleNode(range) && range.startContainer.childNodes[range.startOffset].nodeType !== 3) {
    return range.startContainer.childNodes[range.startOffset]
  }

  // Selection range starts inside a text node, so get its parent
  if (range.startContainer.nodeType === 3) {
    return range.startContainer.parentNode
  }

  // Selection starts inside an element
  return range.startContainer
}

const orderedListRegex = new RegExp(/^\s*\d+\.\s/, 'g')
const unorderedListRegex = new RegExp(/^\s*[*-]\s/, 'g')
export function maybeAutoList(target: HTMLDivElement) {
  const range = getRangeInTarget(target)
  if (!range) return
  const parentElement = getSelectedParentElement(range) as HTMLElement | null
  const maybeListText = parentElement?.textContent

  if (!parentElement || !maybeListText) return

  const orderedMatch = maybeListText.match(orderedListRegex)
  const unorderedMatch = maybeListText.match(unorderedListRegex)

  if (orderedMatch && !document.queryCommandState('insertorderedlist')) {
    const replacedText = parentElement.innerHTML.replace(orderedListRegex, '')
    // Add a line breake to keep the current line, empty elements, make the brower move the selection to previous element
    parentElement.innerHTML = replacedText || '<br/>'
    document.execCommand('insertorderedlist')
    const range = getRangeInTarget(target)
    if (!range) return
    const parent = getSelectedParentElement(range) as HTMLElement | null
    const list = parent?.closest('ol')
    list?.setAttribute('start', `${orderedMatch[0].match(/\d+/g) || 1}`)
  } else if (unorderedMatch && !document.queryCommandState('insertunorderedlist')) {
    const replacedText = parentElement.innerHTML.replace(unorderedListRegex, '')
    // Add a line breake to keep the current line, empty elements, make the brower move the selection to previous element
    parentElement.innerHTML = replacedText || '<br/>'
    document.execCommand('insertunorderedlist')
  }
}

function getHTMLOfSelection() {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    const clonedSelection = range.cloneContents()
    const div = document.createElement('div')
    div.appendChild(clonedSelection)
    return div.innerHTML
  } else {
    return ''
  }
}

export function getMarkdownFromCopiedText(e: React.ClipboardEvent<HTMLDivElement>, plaintext?: boolean) {
  const copiedHtml = getHTMLOfSelection()
  const div = document.createElement('DIV') as HTMLDivElement
  div.innerHTML = copiedHtml
  const markdownFromHtml = convertHtmlToMarkdown({ target: div, plaintext: !!plaintext })
  e.clipboardData.setData('text/plain', markdownFromHtml)
  e.clipboardData.setData('text/html', copiedHtml)
}

const whitelistTags = ['BOLD', 'B', 'I', 'ITALIC', 'EM', 'P', 'DIV', 'UL', 'OL', 'LI']

export function getMarkdownFromPastedText(e: React.ClipboardEvent<HTMLDivElement>, plaintext?: boolean) {
  const pastedText = e.clipboardData.getData('text/plain')
  let pastedHtml = e.clipboardData.getData('text/html')
  const div = document.createElement('DIV') as HTMLDivElement
  div.innerHTML = pastedHtml

  let isMSWord = pastedHtml.includes('urn:schemas-microsoft-com:office:office')
  let isGdocs = !!e.clipboardData.getData('application/x-vnd.google-docs-document-slice-clip+wrapped')

  if (isGdocs) {
    isGdocs = true
    const wrapper = div.querySelector('b[id*=docs-internal]')
    // GDocs uses br on root element for line breaks, but we need explicit p > br to make it work
    Array.from(wrapper?.children || []).forEach(child => child.tagName === 'BR' && (child.outerHTML = '<p><br/></p>'))
    if (wrapper) wrapper.outerHTML = wrapper.innerHTML
  }

  // Both MSWord and GDocs can have some undesired tags, so we need t clean them up
  if (isGdocs || isMSWord) {
    // Remove undesired html tags and attributes
    let cleaned = false
    while (cleaned == false) {
      cleaned = true
      div.querySelectorAll('*').forEach(el => {
        for (let attr in Object.keys(el.attributes)) el.removeAttribute(attr)
        if (whitelistTags.includes(el.tagName) == false) {
          el.outerHTML = el.innerHTML
          cleaned = false
        }
      })
    }

    // Word usually have empty paragraphs with &nbsp;, this created new lines sometimes
    div.querySelectorAll('p').forEach(el => {
      if (!el.innerText.trim() && !el.querySelector('img')) el.innerHTML = '<br/>'
    })

    div.innerHTML = div.innerHTML
      // Remove HTML Comments
      .replace(/(?=<!--)([\s\S]*?)-->/gm, '')
      // Remove new lines between paragraphs
      .replace(/<\/p>\s*<p/gm, '</p><p')
      // Remove extra newlines
      .trim()
  }

  // Cleanup html
  div.querySelectorAll('hr').forEach(el => el.replaceWith(document.createElement('br')))
  div.querySelectorAll('meta, style, script, link').forEach(el => el.remove())

  const text = pastedHtml ? convertHtmlToMarkdown({ target: div, plaintext: !!plaintext }) : pastedText
  return { text, isMSWord }
}

export function getPos(target: HTMLElement) {
  const sel = document.getSelection()
  if (sel && sel.rangeCount > 0) {
    const range = sel.getRangeAt(0)
    if (!range) return null
    const clonedRange = range.cloneRange()
    clonedRange.selectNodeContents(target)
    clonedRange.setEnd(range.endContainer, range.endOffset)
    const pos = clonedRange.toString().length
    clonedRange.detach()

    return pos
  }
  return null
}

const getRangeAt = (element: HTMLElement, pos: number) => {
  let offset = 0
  let found = false
  const range = document.createRange()

  const find = (position: number, parent: HTMLElement | ChildNode) => {
    for (let i = 0; i < parent.childNodes.length; i++) {
      const node = parent.childNodes[i]
      // @ts-ignore
      const nodeLenght = node.length
      if (found) break
      if (node.nodeType === 3) {
        if (offset + nodeLenght >= position) {
          found = true
          range.setStart(node, position - offset)
          break
        } else {
          offset += nodeLenght
        }
      } else {
        find(pos, node)
      }
    }
  }
  find(pos, element)

  return range
}

export function setPositionAt(target: HTMLElement, pos: number) {
  const range = getRangeAt(target, pos)
  const selection = document.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)
}

const noopKeys = ['Alt', 'Control', 'Enter', 'Meta', 'Shift', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function removeStyleAndFormattingTags(target: HTMLDivElement) {
  const lastPos = getPos(target)
  const selection = window.getSelection()
  if (!lastPos || selection?.toString().length) return

  const parent = target.querySelector('font, ul, bold, italic, em, b')
  if (parent) {
    document.execCommand('selectAll')
    document.execCommand('removeFormat')
    setPositionAt(target, lastPos)
  }
}

export function removeSpanProps(target: HTMLDivElement) {
  let spans = Array.from(target.querySelectorAll('span'))

  // Clear styles of remaining spans, this makes sure the spans without link wont be colored
  const spansWithText = spans.filter(s => s.innerText.trim())
  spansWithText.forEach(span => {
    span.removeAttribute('style')
    span.removeAttribute('class')
  })
}

export function wrapPostLinks(target: HTMLDivElement, key?: string) {
  let links = getLinks(target?.innerText)
  let spans = Array.from(target.querySelectorAll('span'))
  const lastPos = getPos(target)

  if (!target || lastPos === null) return
  // Cleanup spans and links that already has exact same text, this way we avoid recreating all spans on every keystroke
  Array.from(spans).map(span => {
    const linkIndex = links.indexOf(span.innerText)
    if (linkIndex > -1) {
      spans = spans.filter(s => s !== span)
      links = [...links.slice(0, linkIndex), ...links.slice(linkIndex + 1)]
      // Make sure link spans have the linkClass, sometimes DOM get splitted and the span get ignored
      span.classList.add(linkClass)
    }
  })

  const selection = window.getSelection()
  if ((!spans.length && !links.length) || !selection || selection?.toString().length) return

  // Clear styles of remaining spans, this makes sure the spans without link wont be colored
  const spansWithText = spans.filter(s => s.innerText.trim())
  spansWithText.forEach(span => {
    span.removeAttribute('style')
    span.removeAttribute('class')
  })

  // Normalize texts to join TextNodes, this way we can safely use setEnd based on offset like we do later
  target.normalize()

  if (links.length) {
    // if (savedPosition.left === 0 && key === 'Backspace') return
    if (noopKeys.includes(`${key}`)) return

    // Sort and reverse links to make sure to convert longer versions of the same link first
    // e.g.: www.google.com, www.google, www.goo
    // If we convert the smaller one first, we will create spans around spans
    links
      .sort()
      .reverse()
      .forEach(link => {
        const matches = Array.from(target.textContent?.matchAll(new RegExp(`${escapeRegExp(link)} ?`, 'g')) || [])
        for (let match of matches) {
          if (match.index === undefined) return
          const isLastCharSpace = match[0].slice(-1)[0] === ' '
          const caretAfterStart = lastPos >= match.index
          const caretBeforeEnd = lastPos <= match.index + link.length + (isLastCharSpace ? 1 : 0)

          // Only deals with links on current caret position, this avoids doing unnecessary parsing for untouched texts
          // Also avoid messing with UndoStack
          if (!caretAfterStart || !caretBeforeEnd) continue

          try {
            // Prevent starting on previous elements
            let range = getRangeAt(target, match.index + 1)
            range.setStart(range.startContainer, range.startOffset - 1)

            let selection = document.getSelection()
            if (!selection || !range) return
            let endRange = getRangeAt(target, match.index + link.length)
            range.setEnd(endRange.endContainer, endRange.endOffset)

            if (range.toString() !== link) return
            selection.removeAllRanges()
            selection.addRange(range)

            document.execCommand('insertHtml', false, `<span class='${linkClass}'>${link}</span>`)
            setPositionAt(target, lastPos)
          } catch (error) {
            console.error(error)
          }
        }
      })
  }
}

export function autoWrapText(target: HTMLDivElement, regex: RegExp, command: string) {
  const lastPos = getPos(target)
  const matches = Array.from(target.textContent?.matchAll(regex) || [])

  if (!target || lastPos === null) return
  const selection = window.getSelection()
  if (!matches || !selection || selection?.toString().length) return

  for (let match of matches) {
    if (match.index === undefined) continue

    try {
      // Prevent starting on previous elements
      let range = getRangeAt(target, match.index + 1)
      range.setStart(range.startContainer, range.startOffset - 1)

      let selection = document.getSelection()
      let endRange = getRangeAt(target, match.index + match[0].length)
      if (!selection || !range || !endRange) return
      range.setEnd(endRange.endContainer, endRange.endOffset)

      if (range.startContainer?.parentElement?.nodeName === 'A' || range.endContainer?.parentElement?.nodeName === 'A')
        return

      // Delete ending character token
      selection.removeAllRanges()
      selection.addRange(endRange)
      document.execCommand('delete')

      // Delete starting character token
      const startRange = range.cloneRange()
      startRange.setEnd(range.startContainer, range.startOffset)
      selection.removeAllRanges()
      selection.addRange(startRange)
      document.execCommand('forwardDelete')

      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand(command)
      const currentRange = selection.getRangeAt(0)
      currentRange.endContainer.parentElement && currentRange.setStartAfter(currentRange.endContainer.parentElement)
      document.execCommand(command)
      // Insert white space to prevent continuing on bold/italic text after converting
      document.execCommand('insertText', false, ' ')
    } catch (error) {
      console.error(error)
    }
  }
}

/** Remove links and images from markdown for SEO metatags */
export function cleanupAndLimitStringForSEO(str: string | undefined, limit = 160) {
  if (!str) return ''

  str = str.replace(/"/g, "'")

  const markdownImageRegex = /!?\[(.*?)\]\(.*?\)/gm
  const result = str.replace(markdownImageRegex, '$1')

  if (result.length <= limit) return result
  return `${result.substr(0, limit - 1)}…`
}

export function isFocusedAtEndOrStart(target?: HTMLDivElement | null) {
  if (!target) return {}
  const selection = document.getSelection()
  const isFocused = selection?.containsNode(target, true)
  if (!isFocused) return {}
  const end = selection?.getRangeAt(0).cloneRange()
  end?.setEndAfter(target)
  const start = selection?.getRangeAt(0).cloneRange()
  start?.setStartBefore(target)
  return { isAtEnd: !end?.toString(), isAtStart: !start?.toString() }
}
