import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import { cx } from '@linaria/core'
import { useMentionsContext } from './Mentions'
import CSLink from '../../composing/CSLink'
import { MarkdownLinkStyles } from '../../components/styles'
import { useUpdateLinkedMentions } from './utils'
import { LinkProps } from 'next/link'
import { addProtocol } from './csMarkdown'
import config from 'src/config'

function CSMarkdownLink(props: LinkProps & { children: ReactNode; href: string }) {
  const linkRef = useRef<HTMLAnchorElement>(null)
  let url: null | URL = null
  let { href, children } = props
  let origin = typeof window !== 'undefined' ? window.location.origin : config.originUrl
  let host = typeof window !== 'undefined' ? window.location.host : ''
  href = addProtocol(props.href) as string
  try {
    // if its a relative link, prepend origin
    url = new URL((href.startsWith('/') ? origin : '') + href)
  } catch (err) {
    console.error('failed to parse link', { href, origin })
    // @NOTE: failsafe url just incase we can't parse a valid link
    url = new URL(origin)
  }
  let mentions = useMentionsContext()
  const updateLinkedMentions = useUpdateLinkedMentions()

  let isInternal = url?.host === host || url?.host === new URL(config.originUrl || '').host
  let possibleMention = isInternal && /^(?:@|\$)(?:\w+\.[xX]|\w*)$/.test(String(props.children))
  // Use slice 1 to ignore the mention char ($, @)
  let isProfileMention = possibleMention && url?.pathname === `/${String(props.children).slice(1)}`
  let isAssetMention = possibleMention && /^\/asset\/[\w\d]+:\w+$/.test(url?.pathname || '')

  const assetStatusClass = useMemo(() => {
    if (!url || !isAssetMention) return ''
    let parts = url.pathname.split('/')
    let [symbol, type] = parts[2].split(':')
    let matchingMention = mentions.asset_mentions?.find(a => a.symbol === symbol && a.type === type)
    let change = matchingMention?.change_percentage
    return change === undefined
      ? 'asset-mention-pending'
      : change > 0
      ? 'positive'
      : change < 0
      ? 'negative'
      : 'neutral'
  }, [isAssetMention, mentions.asset_mentions, url])

  const profileStatusClass = useMemo(() => {
    if (!url || !isProfileMention) return ''
    let matchingMention = mentions.user_mentions?.find(a => a.username === url?.pathname)
    return matchingMention ? '' : 'user-mention-pending'
  }, [isProfileMention, mentions.user_mentions, url])

  const baseCssClass = useMemo(() => {
    if (isProfileMention) return `user-mention ${profileStatusClass}`
    else if (isAssetMention) return `asset-mention ${assetStatusClass || 'asset-mention-pending'}`
    else return 'link'
  }, [assetStatusClass, isAssetMention, isProfileMention, profileStatusClass])

  useEffect(() => {
    if (!linkRef.current) return
    // This only runs if the current link is an asset mention and it wasnt pre hydrated by BE
    if (baseCssClass.includes('-pending')) {
      updateLinkedMentions(linkRef.current)
    }
  }, [assetStatusClass, updateLinkedMentions, baseCssClass])

  let to = isInternal && url ? url.pathname + url.search : href
  if (!url || mentions.disableLinks) return children
  let parts = url.pathname.split('/')
  let [symbol, type] = (parts[2] || '')?.split(':') || []

  return (
    <CSLink
      ref={linkRef}
      {...props}
      href={to}
      capture
      unstyled
      className={cx(
        MarkdownLinkStyles,
        baseCssClass,
        isAssetMention && (assetStatusClass || 'asset-mention-pending'),
        isProfileMention && 'user-mention user-mention-pending'
      )}
      external={!isInternal}
      data-symbol={symbol}
      data-type={type}
      data-original={String(children)}
    >
      {children}
    </CSLink>
  )
}

export default CSMarkdownLink
