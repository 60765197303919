import React, { Fragment } from 'react'
import { Expander, FlexRow } from '../../composing/Flex'
import { SkeletonBlock } from '../../components/Skeleton'
import { SpacerV } from '../../composing/Spacing'
import { UserBlockSkeleton } from '../../components/UserBlock.skeleton'
import { ContentCard } from '../../components/styles'
import { useDev } from '../dev/DevContext'
import { cx } from '@linaria/core'
import { devTransparent, devTransparentOutline } from '../../theme/AtomicClasses'

export default function FeedSkeleton() {
  const { skeletonShow } = useDev()
  return (
    <div className={cx(skeletonShow && devTransparent)}>
      {[...Array(2)].map((_, i) => (
        <Fragment key={i}>
          <SingleImageCardSkeleton />
          <DoubleImageCardSkeleton />
        </Fragment>
      ))}
    </div>
  )
}

export function SingleImageCardSkeleton() {
  const { skeletonShow } = useDev()
  return (
    <ContentCard className={cx(skeletonShow && devTransparentOutline)}>
      <UserBlockSkeleton />
      <SpacerV />
      <SkeletonBlock w={'75%'} h={'1.5rem'} />
      <SpacerV />
      <SkeletonBlock w={'100%'} h={'18rem'} />
    </ContentCard>
  )
}

export function DoubleImageCardSkeleton() {
  const { skeletonShow } = useDev()
  return (
    <ContentCard className={cx(skeletonShow && devTransparentOutline)}>
      <UserBlockSkeleton />
      <SpacerV />
      <SkeletonBlock w={'75%'} h={'1.5rem'} />
      <SpacerV />
      <SkeletonBlock w={'100%'} h={'1rem'} />
      <SpacerV rem={0.5} />
      <SkeletonBlock w={'75%'} h={'1rem'} />
      <SpacerV />
      <FlexRow>
        <SkeletonBlock w={'49%'} h={'9rem'} />
        <Expander />
        <SkeletonBlock w={'49%'} h={'9rem'} />
      </FlexRow>
    </ContentCard>
  )
}

export function TagsFiltersSkeleton() {
  const { skeletonShow } = useDev()
  return (
    <FlexRow className={cx(skeletonShow && devTransparent)}>
      {[...Array(8)].map((_, i) => (
        <SkeletonBlock key={i} w={'12.5%'} h={'1.75rem'} />
      ))}
    </FlexRow>
  )
}
