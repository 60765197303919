import {
  useDeleteUserWatchlist,
  usePostWatchlistAction,
  AddWatchlistParams,
  useGetUserWatchlist,
  useGetUserHoldings
} from '@commonstock/common/src/api/user'
import { useCallback, useMemo } from 'react'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useUser } from '../auth/AuthContext'
import { Periods, AssetKind, AssetType } from '@commonstock/common/src/types'

export enum HoldingsMode {
  CurrentPrice = 'CurrentPrice',
  PercentChange = 'PercentChange',
  TotalReturn = 'TotalReturn',
  TotalValue = 'TotalValue'
}

const currentPeriodKey = 'cs:portfolio-current-period'
const holdingsModeKey = 'cs:holdings-mode:2'

export function usePortfolio() {
  const [currentPeriod, setCurrentPeriod] = usePersistedState<Periods>(currentPeriodKey, Periods['1D'])
  const [holdingsMode, setHoldingsMode] = usePersistedState<HoldingsMode>(holdingsModeKey, HoldingsMode.TotalReturn)

  const [user] = useUser()
  const paused = { paused: !user }
  const user_uuid = user?.user_uuid || ''
  const [positions, , positionsPending] = useGetUserHoldings({ meta: { user_uuid } }, paused)
  const [userWatchlist, , userWatchlistPending, refetchWatchlist] = useGetUserWatchlist({ meta: { user_uuid } }, paused)

  const portfolioPending = useMemo(() => {
    return positionsPending || userWatchlistPending
  }, [positionsPending, userWatchlistPending])

  const holdings = useMemo(() => {
    if (!positions) return []
    return positions.positions
      .filter(p => p.asset_type !== AssetType.currency)
      .sort((a, b) => b.portfolio_percentage - a.portfolio_percentage)
  }, [positions])

  const watchlist = useMemo(() => {
    if (!userWatchlist) return []
    return userWatchlist.filter(a => a.asset_type !== AssetKind.currency).sort((a, b) => b.watched_at - a.watched_at)
  }, [userWatchlist])

  const setPeriod = useCallback(
    (value: Periods) => {
      setCurrentPeriod(value)
    },
    [setCurrentPeriod]
  )

  const setHoldingsView = useCallback(
    (value: HoldingsMode) => {
      setHoldingsMode(value)
    },
    [setHoldingsMode]
  )

  return {
    currentPeriod,
    holdings,
    holdingsMode,
    refetchWatchlist,
    setHoldingsView,
    setPeriod,
    watchlist,
    portfolioPending
  }
}

export const useAddToWatchlist = () => {
  const postWatchlist = usePostWatchlistAction()

  const addAsset = useCallback(
    async ({ json }: AddWatchlistParams) => {
      try {
        await postWatchlist({ json })
      } catch (err) {
        console.log('## addAsset err:', err)
        alert('Something went wrong. Please try again later.')
      }
    },
    [postWatchlist]
  )

  return addAsset
}

export const useDeleteWatchlistAsset = () => {
  const deleteWatchlist = useDeleteUserWatchlist()

  const deleteAsset = useCallback(
    async ({ id }: { id: number }) => {
      try {
        await deleteWatchlist({ meta: { id } })
      } catch (err) {
        console.log('## deleteAsset err:', err)
        alert('Something went wrong. Please try again later.')
      }
    },
    [deleteWatchlist]
  )

  return deleteAsset
}
