import React, { useCallback, useState, useMemo } from 'react'
import { sendbird } from '../chat/ChatContext'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { useCreateConversation } from '@commonstock/common/src/api/chat'
import ChannelSelectMembers from './ChannelSelectMembers'
import AnimatedEllipsis from '../../components/AnimatedEllipsis'
import { Checkmark } from '../../theme/NewIcons'
import { useChannels } from '../chat/ChannelContext'
import { Footer } from '../chat/ChatModals'
import { PostState, PostStage } from '@commonstock/common/src/types'
import { FormFail } from '../../components/FormFail'
import { SpacerV } from '../../composing/Spacing'
import { track } from '../analytics/mixpanel'
import { uniq } from 'lodash'
import { PrimaryButton } from '../../composing/CSButton'
import { Modal } from '../modal/Modals'
import { useModal } from 'src/scopes/modal/Modal'

const NewConversation = () => {
  const [selectedProfiles, setSelectedProfiles] = useState<HydratedUserProfile[]>([])
  const { getUpdatedChannelAndSelect } = useChannels()
  const createConversation = useCreateConversation()
  const [postState, setPostState] = useState<PostState>({
    stage: PostStage.Latent
  })
  const { actions } = useModal()
  const [search, setSearch] = useState('')

  const currentUserId = sendbird.currentUser?.userId

  const onClickCreateConversation = useCallback(async () => {
    track('SubmitCreateDM:Tap')

    if (!selectedProfiles.length) return
    if (postState.stage === PostStage.Success || postState.stage === PostStage.Pending) return

    const selectedUserIds = selectedProfiles.map(p => p.uuid)
    const user_uuids = uniq([currentUserId, ...selectedUserIds])
    try {
      setPostState({ stage: PostStage.Pending })
      const response = await createConversation({ json: { user_uuids } })
      if (!response.fail && response.success?.payload) {
        setPostState({ stage: PostStage.Success })
        await getUpdatedChannelAndSelect(response.success?.payload.channel_url)
        actions.close()
      }
    } catch (error) {
      console.error('### Error while creating conversation', error.fail)
      setPostState({ stage: PostStage.Fail, fail: error.fail })
    }
  }, [selectedProfiles, postState.stage, currentUserId, createConversation, getUpdatedChannelAndSelect, actions])

  const isCreating = useMemo(() => postState.stage === PostStage.Pending, [postState.stage])
  const isSuccessfull = useMemo(() => postState.stage === PostStage.Success, [postState.stage])

  return (
    <Modal title={'New message or group'} noPad width={27} hasFooter>
      {postState.fail && (
        <>
          <SpacerV />
          <FormFail message={postState.fail.payload.error} />
        </>
      )}
      <ChannelSelectMembers
        currentUserId={currentUserId}
        selectedProfiles={selectedProfiles}
        setSelectedProfiles={setSelectedProfiles}
        search={search}
        setSearch={setSearch}
        isNew
      />
      <Footer>
        <PrimaryButton
          block
          large
          disabled={isCreating || !selectedProfiles.length}
          onClick={onClickCreateConversation}
        >
          {isCreating ? (
            <AnimatedEllipsis>Creating your direct message</AnimatedEllipsis>
          ) : isSuccessfull ? (
            <>
              Direct message successfully created! <Checkmark />
            </>
          ) : (
            'Create DM'
          )}
        </PrimaryButton>
      </Footer>
    </Modal>
  )
}

export default NewConversation
