import { useGetMyProfile, usePostEthConnect } from '@commonstock/common/src/api/profile'
import { ethers } from 'ethers'
import { useCallback } from 'react'
import CSLink from 'src/composing/CSLink'
import { CSText } from 'src/composing/CSText'
import ErrorModal from '../error/ErrorModal'
import { useEphemeralModal } from '../modal/Modal'

export default function useLinkMetamask() {
  const ethConnect = usePostEthConnect()
  const [profile] = useGetMyProfile()
  let modal = useEphemeralModal()
  let linkMetamask = useCallback(async () => {
    try {
      let isConnected = await window.ethereum?.request({ method: 'eth_requestAccounts' })
      if (!isConnected) {
        modal(null)
        // @NOTE using a settimeout for now to make sure the error modal is not closed out.
        setTimeout(() => {
          modal(
            <ErrorModal
              text={
                <CSText>
                  <CSLink href={'https://metamask.io/'}>Please install metamask and retry</CSLink>
                </CSText>
              }
            />
          )
        })
        return
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      let message = 'Commonstock Verify ' + profile?.user_uuid
      let signature = await signer.signMessage(message)
      await ethConnect({ json: { signature, message } })
      return signer.getAddress()
    } catch (err) {
      modal(null)
      setTimeout(() => modal(<ErrorModal text={err.message} />))
    }
  }, [modal, ethConnect, profile])
  return linkMetamask
}
