import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useCreatePostAction,
  useUpdatePostAction,
  useDeletePostAction,
  UpdatePostParams,
  CreatePostParams,
  usePinPostAction
} from '@commonstock/common/src/api/post'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { TagItemType } from '@commonstock/common/src/types'
import { Tag, useGetItemTags } from '@commonstock/common/src/api/tag'
import { useUpdateTags } from '../profile/EditTags'
import { ShareType, useGetLinkPreviewAction } from '@commonstock/common/src/api/link'

type PostData = UpdatePostParams | CreatePostParams
export function useCreateOrUpdatePost() {
  const postPost = useCreatePostAction()
  const putPost = useUpdatePostAction()

  const createOrUpdatePost = useCallback(
    (data: PostData) => {
      // @ts-ignore ts doesnt infer we are in the correct case as it should based on the existence of the uuid property
      return data.json.uuid ? putPost(data) : postPost(data)
    },
    [putPost, postPost]
  )

  return createOrUpdatePost
}

export function useDeletePost() {
  const deletePost = useDeletePostAction()

  const removePost = useCallback(
    ({ uuid }: { uuid: string }) => {
      return deletePost({ meta: { uuid } })
        .then(data => data)
        .catch(err => {
          console.error('## deleteMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [deletePost]
  )

  return removePost
}

export function useRefetchPostDependents(ignoreFeed?: boolean) {
  const refetchDependents = useRefetchByKeys(
    'get-post',
    'get-trade',
    'get-following-feed',
    'get-users-posts',
    'get-asset-trades',
    'get-user-trades',
    ignoreFeed ? '' : 'get-global-feed',
    ignoreFeed ? '' : 'get-tagged-content'
  )
  return refetchDependents
}

type UseEditPostTagsProps = {
  parentUuid?: string
  parentType: TagItemType
}
export function useEditPostTags({ parentUuid = '', parentType }: UseEditPostTagsProps) {
  const previousUuidRef = useRef(parentUuid)
  const [fetchTags, , tagsPending] = useGetItemTags({ meta: { uuid: parentUuid } }, { paused: !parentUuid })
  const [tags, setTags] = useState<Tag[]>([])

  useEffect(() => {
    if (parentUuid && !tagsPending) {
      previousUuidRef.current = parentUuid
      fetchTags && setTags(fetchTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsPending])

  const updateTags = useUpdateTags()
  const saveTags = useCallback(async (uuid: string) => await updateTags(fetchTags || [], tags, uuid, parentType), [
    tags,
    fetchTags,
    parentType,
    updateTags
  ])

  const removeTag = useCallback((tag: Tag) => setTags(tags.filter(t => t.canonical_name !== tag.canonical_name)), [
    tags
  ])

  return { tags, setTags, saveTags, removeTag }
}

export function useUpdateLinkPreview() {
  const fetchLinkPreview = useGetLinkPreviewAction()

  const updateLinkPreview = useCallback(
    (type: ShareType.POST | ShareType.TRADE, uuid: string, skip_cache: boolean) => {
      fetchLinkPreview({ meta: { type, uuid }, query: { skip_cache } }).catch(fail => {
        // Silently fails
        console.error('## could not create share link', fail)
      })
    },
    [fetchLinkPreview]
  )

  return updateLinkPreview
}

export function usePinPost() {
  const pinPost = usePinPostAction()
  const pinnedPost = useCallback(
    ({ uuid, pinned }: { uuid: string; pinned: boolean }) => {
      return pinPost({ meta: { uuid }, json: { pinned } })
        .then(data => data)
        .catch(err => {
          console.error('## pinMemo err:', err)
          // @TODO implement real error handling
          alert('Something went wrong. Please try again.')
          return err
        })
    },
    [pinPost]
  )
  return pinnedPost
}
