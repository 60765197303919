import { useCallback, useState } from 'react'
import { usePostMediaAction } from '@commonstock/common/src/api/media'
import uriToBlob from '@commonstock/common/src/utils/uriToBlob'
import { captureException, unexpectedError } from '../../dev/sentry'
import { Gif, isGif } from '@commonstock/common/src/api/gifs'

export function useCreateMediaLink({ silent }: { silent: boolean } = { silent: false }) {
  const postMedia = usePostMediaAction()
  const [pending, pendingSet] = useState(false)

  const createMedia = useCallback(
    async (file: string) => {
      pendingSet(true)
      let body = uriToBlob(file)
      return postMedia({ body })
        .then(data => {
          pendingSet(false)
          return data
        })
        .catch(err => {
          pendingSet(false)
          if (silent) {
            captureException(err)
          } else {
            unexpectedError(err)
            throw err
          }
        })
    },
    [postMedia, silent]
  )

  const createMediaLink = useCallback(
    async (file: string | File | Gif, external?: boolean) => {
      let fileData = ''
      if (file instanceof File) {
        fileData = await new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.readAsDataURL(file)
          // @ts-ignore ??
          reader.onload = e => resolve(e.target?.result)
          reader.onerror = error => reject(error)
        })
      } else fileData = isGif(file) ? file.images.original.url : file
      let blob = fileData
      if (fileData.includes('.gif') || external) {
        blob = (await fetch(fileData)
          .then(res => res.blob())
          .then(async res => {
            return new Promise(resolve => {
              const a = new FileReader()
              // @ts-ignore
              a.onload = e => resolve(e.target?.result)
              a.readAsDataURL(res)
            })
          })
          .catch(err => {
            if (silent) {
              // ignore error
            } else {
              unexpectedError(err)
              throw err
            }
          })) as string
      }
      const data = await createMedia(blob)
      if (data && data.success) {
        return data.success.payload.url
      }
    },
    [createMedia, silent]
  )

  return {
    createMediaLink,
    pending
  }
}
