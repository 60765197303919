import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { AssetType, Periods } from '../types'
import { HydratedUserProfile } from './profile'

export type Trend = {
  asset_symbol: string
  asset_type: AssetType
  frequency: null
  percent: number
  order_type: string
  asset_short_name: string
}

export type InvestingTrending = {
  trends: {
    trend: Trend
    users: HydratedUserProfile[]
  }[]
}

export enum TrendingFilter {
  Following = 'follow',
  Global = 'global',
  Top = 'top'
}

export enum TrendingOrderType {
  Buy = 'buy',
  Sell = 'sell'
}
type TrendingInvestingParams = {
  query: {
    period?: Periods
    limit?: number
    type?: TrendingFilter
    order_side?: TrendingOrderType
  }
}

export const useGetInvestingTrending = createUseFetch<InvestingTrending, TrendingInvestingParams>({
  key: 'investing-trending',
  path: `/recommendation/trending/investing/v2`
})
type TrendingParams = {
  query: {
    period?: Periods
    limit?: number
    type?: TrendingFilter
  }
}

export type MentionTrending = {
  trends: {
    trend: {
      asset_symbol: string
      asset_type: AssetType
      num_mentions: null
      last_price: number | null
      short_name: string | null
      percent_of_total_mentions: number
    }
    users: HydratedUserProfile[]
  }[]
}
export const useGetTrendingMentions = createUseFetch<MentionTrending, TrendingParams>({
  key: 'mention-trending',
  path: '/recommendation/trending/asset-mentions/v2'
})
type TopAuthor = {
  name: string
  picture: string
  total_like_value: number | null
  user_uuid: string
  username: string
}
type TopAuthorParams = {
  query: {
    limit?: number
    period?: Periods
    last_uuid?: string
  }
}

export const useGetTopAuthors = createUseFetch<TopAuthor[], TopAuthorParams>({
  key: 'top-authors',
  path: '/recommendation/discover/top-authors'
})
type SuggestedUserParams = {
  query: {
    limit?: number
  }
}

export const useGetSuggestedUsers = createUseFetch<HydratedUserProfile[], SuggestedUserParams>({
  key: 'suggested-users',
  path: '/recommendation/discover/suggested-users'
})

type ContentSearchParams = {
  query: {
    limit: number
    term: string
  }
}

export type ContentSearch = {
  author_uuid: string
  content: string
  title: string | null
  uuid: string
}

export const useContentSearchAction = createUseFetchAction<ContentSearch[], ContentSearchParams>({
  key: 'content-search-action',
  path: '/search/content'
})
