import React, { ReactNode } from 'react'
import { ActionModal } from '../modal/Modals'
import { SecondaryButton } from 'src/composing/CSButton'
import { CSText } from 'src/composing/CSText'
import { SpacerV } from 'src/composing/Spacing'
import { useModal } from '../modal/Modal'
import { Colors } from 'src/theme/constants'

type Props = {
  text: string | ReactNode
}

const ErrorModal = ({ text }: Props) => {
  const { actions } = useModal()

  return (
    <ActionModal title="Failed to connect">
      <CSText centered color={Colors.TextNegative}>
        {text}
      </CSText>
      <SpacerV />
      <SecondaryButton onClick={actions.close}>Okay</SecondaryButton>
    </ActionModal>
  )
}

export default ErrorModal
