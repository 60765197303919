import React, { useCallback, useRef, useState, ReactNode, useMemo, useContext, useLayoutEffect, useEffect, useImperativeHandle, Ref } from 'react';
import { renderToString } from 'react-dom/server';
import { chatMarkdown, postsMarkdown, regexEmoji, wrapEmojiCharacters, getEmojiImageElement, Formatter, commentsMarkdown, isMarkdownParseableRegex } from './csMarkdown';
import useDragFile from '../../utils/useDragFile';
import { useCreateMediaLink } from '../trade/tradeHooks';
import { Colors } from '../../theme/constants';
import { cx, css } from '@linaria/core';
import MentionsContext from './Mentions';
import { useKeyDown } from '@commonstock/common/src/utils/useKeyPress';
import debounce from 'lodash.debounce';
import { addMentionToElement, maybeAutoList, convertHtmlToMarkdown, getMarkdownFromCopiedText, getRangeInTarget, getRangeOrEnd, mentionDetect, preHydrateText, resetSelectionRange, maybeUnwrapLinkedMention, MentionDetected, useUpdateLinkedMentions, modifySelection, getMarkdownFromPastedText, removeLink, autoWrapText, removeSpanProps, isFocusedAtEndOrStart } from './utils';
import { ClientProvider } from '@commonstock/client/src/react/context';
import client from '../../client';
import { MentionAttachments } from '@commonstock/common/src/types/mentions';
import unified from 'unified';
import { useEphemeralModal } from '../modal/Modal';
import { openLinkModal } from './Toolbar';
import { Gif } from '@commonstock/common/src/api/gifs';
import { baseTextStyle } from '../../components/styles';
import { TextEditorValue, Helper, TextEditorContext, Listener, HelperType, HelperPayload, HelperOwnedKeys } from './TextEditorContext';
import { HydratedLinkItem, useGetHydratedLink } from '@commonstock/common/src/api/post';
import { getLinks, urlRegex } from '../post/utils';
import { HTMLDivProps } from '../../utils/types';
import { useFlags } from 'src/scopes/feature-flags/useFlags';
import { defaultInsertFiles, handlePastedImages } from './utils-image';
import { captureException } from 'src/dev/sentry'; // this is a global configuration, we do it here for findability later

typeof document !== 'undefined' && document.execCommand?.('defaultParagraphSeparator', false, 'p');
type Props = {
  className?: string;
  initialText?: string;
  initialMentions?: MentionAttachments;
  children: ReactNode;
  placeholder?: string;
  persistKey?: string | false;
  dropFilesCallback?: false | ((_f: Array<File>) => void);
  insertFilesCallback?: (_f: Array<File | string | Gif>) => void;
  markdownProcessor?: unified.Processor<unified.Settings>;
  editPost?: boolean;
  linkPreview?: HydratedLinkItem | false;
  setLinkPreview?: (_value: HydratedLinkItem | false | undefined) => void;
  setLinkPreviewPending?: (_value: boolean) => void;
  linkPreviewPending?: boolean;
  shouldRemoveLink?: boolean;
  shouldFocusStart?: boolean;
  lineBreak?: 'allow' | 'modifier-keys-only' | 'none';
  onNavigate?: (e: KeyboardEvent) => void;
};

function _Provider({
  className,
  initialText,
  initialMentions = {},
  children,
  placeholder = '',
  persistKey,
  dropFilesCallback,
  insertFilesCallback,
  markdownProcessor,
  editPost,
  linkPreview,
  setLinkPreview,
  setLinkPreviewPending,
  linkPreviewPending,
  shouldRemoveLink,
  shouldFocusStart,
  lineBreak = 'allow',
  onNavigate
}: Props, ref: Ref<TextEditorValue>) {
  const {
    webEmojiToImage
  } = useFlags();
  markdownProcessor = markdownProcessor ? markdownProcessor : commentsMarkdown;
  let plaintext = ![postsMarkdown, commentsMarkdown].includes(markdownProcessor);
  let targetRef = useRef<HTMLDivElement>(null);
  let modal = useEphemeralModal();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  let focus = useCallback((toStart?: boolean | undefined) => {
    resetSelectionRange(getRangeOrEnd(targetRef.current, toStart, toStart));
  }, []);
  let [mentionData, setMentionData] = useState<MentionDetected | null>(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  let listenersRef = useRef<Set<Listener>>(new Set());
  const listen = useCallback((cb: (_el: HTMLDivElement) => void) => {
    listenersRef.current.add(cb);
    if (targetRef.current) cb(targetRef.current);
    return () => {
      listenersRef.current.delete(cb);
    };
  }, []);
  const getMarkdown = useCallback(() => {
    return convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext
    });
  }, [plaintext]);
  const getPlaintext = useCallback(() => {
    return convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext,
      returnPlaintext: true
    });
  }, [plaintext]);
  const getMentions = useCallback(() => {
    const {
      mentions
    } = preHydrateText(getPlaintext());
    return mentions;
  }, [getPlaintext]);
  const getPlaintextLength = useCallback(() => {
    const text = convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext,
      returnPlaintext: true
    });
    let urlLength = (0 as number);
    ((text || '').match(urlRegex) || []).forEach(url => {
      urlLength = urlLength + (url.startsWith(' ') || url.startsWith('\t') || url.startsWith('\n') ? 25 : 24);
    }); // Links should be counted as 24 characters. Since this regex includes the space before, it can be 25.

    const textWithoutUrls = text.replace(urlRegex, '').replace(/\n\n/g, 'x').length;
    return textWithoutUrls + urlLength;
  }, [plaintext]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [mentionedWithAutoSpace, setMentionedWithAutoSpace] = useState<null | string>(null);
  const getHydratedLinks = useGetHydratedLink();
  let checkLink = useCallback(() => {
    if (setLinkPreview && targetRef.current) {
      const hydrateLink = async () => {
        const links = getLinks(targetRef.current?.innerText);
        const firstLink = links ? links[0] : '';

        if (firstLink) {
          const response = await getHydratedLinks({
            json: {
              urls: [firstLink]
            }
          });

          if (response.success?.payload && Object.keys(response.success.payload).length > 0 && Object.values(response.success.payload)[0].title && targetRef.current) {
            if (shouldRemoveLink) {
              targetRef.current.innerHTML = removeLink(targetRef, targetRef.current?.innerHTML || '', firstLink, focus);
            }

            setLinkPreview(Object.values(response.success?.payload)[0]);
          }

          setLinkPreviewPending && setLinkPreviewPending(false);
        }
      };

      const links = getLinks(targetRef.current?.innerText);
      const firstLink = links ? links[0] : false;

      if (!linkPreview || linkPreview.url !== firstLink) {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
          setLinkPreviewPending && setLinkPreviewPending(false);
        }

        if (linkPreview === false) {
          if (links?.length === 0 && !linkPreviewPending) {
            setLinkPreview(undefined);
          }

          return;
        }

        if (!!firstLink && (linkPreview ? linkPreview.url !== firstLink : true)) {
          setLinkPreviewPending && setLinkPreviewPending(true);
          setTimer(setTimeout(() => {
            hydrateLink();
          }, 1000));
        } else if (linkPreview && !linkPreviewPending) {
          setLinkPreview(undefined);
        }
      }
    }

    return () => {};
  }, [focus, getHydratedLinks, linkPreview, linkPreviewPending, setLinkPreview, setLinkPreviewPending, shouldRemoveLink, timer]); // anytime something changes, we need to check for mentions and set helper state accordingly

  let checkMention = useCallback((e?: React.FormEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!targetRef.current) return;
    setIsEmpty(targetRef.current.innerText.trim() === '' && !targetRef.current?.querySelector('ul, ol, img'));
    if (!plaintext) maybeAutoList(targetRef.current);
    const nativeEvent = e?.nativeEvent; // @ts-ignore need to discover how to tye this event

    const typedChar = (nativeEvent?.data || '' as string);
    const mentionDetected = mentionDetect(targetRef.current); // @ts-ignore need to discover how to tye this event

    const inputType = (nativeEvent?.inputType || '' as string); // When changing an mention link text, it unwrapps the link if the user remove any character from the current mention
    // Prevent unwrapping from running when Undoing or Redoing
    // @NOTE: This has one remaining bug, when undoing the mention will still be missing 1 character

    if (mentionDetected.range && !inputType?.includes('history')) maybeUnwrapLinkedMention(targetRef.current, mentionDetected.range); // If the user has just mentioned something, and it has auto space added to the end,
    // we adjust some characters to being collapsed to the text on next hit

    if (mentionedWithAutoSpace) {
      const selection = document.getSelection();
      const range = selection?.getRangeAt(0).cloneRange();

      if (selection && range && mentionedWithAutoSpace && typedChar.match(/[,.\])}!?]/g)) {
        range.setStart(range.startContainer, 0);
        const match = range.toString().endsWith(` ${typedChar}`);
        if (!match) return;
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('insertText', false, `${typedChar} `);
      }

      setMentionedWithAutoSpace(null);
    } // Replace inserted emoji by a image since browsers has some alignment issues


    let selection = document.getSelection();

    if (selection && webEmojiToImage && typedChar.match(regexEmoji)) {
      // When adding emoji from touchbar, it takes a different flow from selecting on screen
      if (typedChar.endsWith(' ')) document.execCommand('undo');else modifySelection(selection, 'extend', 'left', 'character');
      const wrapper = document.createElement('span');
      const fontSize = window.getComputedStyle(targetRef.current).fontSize || '24px';
      wrapper.appendChild(getEmojiImageElement(typedChar, fontSize));
      document.execCommand('insertHtml', false, wrapper.innerHTML);
    }

    setMentionData(mentionDetected.type ? mentionDetected : null);
    if (targetRef.current?.classList.contains('mentioning')) return;
  }, [mentionedWithAutoSpace, plaintext, webEmojiToImage]);
  let checkLiveEdit = useCallback((e?: React.KeyboardEvent<HTMLDivElement>) => {
    if (!targetRef.current) return; // @NOTE this will clear out the div so the placeholder reappears on safari

    if (markdownProcessor === chatMarkdown && targetRef.current && targetRef.current.textContent === '') {
      targetRef.current.innerHTML = '';
    }

    if (!plaintext && (e?.key === '*' || e?.key === '_' || e?.key === ' ') && targetRef.current) {
      const mentionDetected = mentionDetect(targetRef.current);

      if (!mentionDetected.type) {
        autoWrapText(targetRef.current, new RegExp(/_([^\s][^_]+?[^\s])_/, 'gm'), 'italic');
      }

      autoWrapText(targetRef.current, new RegExp(/\*([^\s][^*]+?[^\s])\*/, 'gm'), 'bold');
    }

    requestIdleCallback(() => {
      if (!targetRef.current) return;
      if (!plaintext && setLinkPreview) checkLink();
      removeSpanProps(targetRef.current);
    }, {
      timeout: 500
    });
  }, [checkLink, markdownProcessor, plaintext, setLinkPreview]);
  const {
    createMediaLink
  } = useCreateMediaLink({
    silent: true
  });
  const [isUploading, setIsUploading] = useState(false);
  const insertFiles = useCallback(async (files: Array<File | string | Gif>, isMediaLink?: boolean) => {
    if (insertFilesCallback) insertFilesCallback(files);else defaultInsertFiles({
      target: targetRef.current,
      files,
      isMediaLink,
      createMediaLink,
      setIsUploading,
      setIsEmpty
    });
  }, [createMediaLink, insertFilesCallback]);
  let {
    dragHandlers
  } = useDragFile(dropFilesCallback === false ? () => {} : dropFilesCallback || insertFiles);
  const updateLinkedMentions = useUpdateLinkedMentions(); // special paste handler, will detect and embed media
  // and will paste only the plain text version of the clipboard
  // @NOTE when copying styled text, the clipboard will typically have two items representing the same text, one for html and one for plain

  const onPaste = useCallback((e: any) => {
    e.preventDefault();
    const isPastable = markdownProcessor === postsMarkdown || markdownProcessor === commentsMarkdown;
    let isMSWordPasted = false;

    try {
      const {
        items
      } = e.clipboardData;

      for (let item of items) {
        if (!isMSWordPasted && isPastable && String(item.type).startsWith('image/')) {
          const file: File = item.getAsFile();
          file && dropFilesCallback ? dropFilesCallback([file]) : insertFiles([file]);
        } else if (item.type === 'text/plain') {
          // get text representation of clipboard
          const {
            text: draft,
            isMSWord
          } = getMarkdownFromPastedText(e);
          isMSWordPasted = isMSWord;
          const {
            text,
            mentions
          } = preHydrateText(draft); // If text is a plain text single lined (no markdown), we dont need to format it before pasting

          if (draft === text && !isMarkdownParseableRegex.test(text) && !/\n/.test(text) && !(webEmojiToImage && draft.match(regexEmoji))) {
            document.execCommand('insertHTML', false, text);
            return;
          }

          let wrapper: HTMLElement | null = document.createElement('SPAN');
          wrapper.innerHTML = renderToString(<ClientProvider client={client}>
                <Formatter text={text} mentions={mentions} processor={markdownProcessor} />
              </ClientProvider>);
          wrapper = wrapper.querySelector(`.${baseTextStyle}`);
          if (!wrapper) return;
          const imgs = wrapper.querySelectorAll('img'); // Add CORS policy to image, to be able to get Gif and outter sources
          // imgs.forEach(el => (el.crossOrigin = 'Anonymous'))

          imgs.forEach(el => isPastable ? el.classList.add('pasted-image') : el.remove());
          wrapEmojiCharacters(wrapper);
          const range = document.getSelection()?.getRangeAt(0);
          const endContainer = range?.endContainer;
          const parentElement = endContainer && (endContainer.nodeType === 3 ? endContainer.parentNode : endContainer) || undefined; // To prevent soft breaks inside list items, insert each new line/child in a different paragraph/list-item

          if (parentElement?.nodeName === 'LI') {
            const normalizedChilds = [...wrapper.children].flatMap(c => /^UL|OL$/.test(c.tagName) ? [...c.children] : c);

            for (let child of normalizedChilds) {
              document.execCommand('insertHTML', false, child.innerHTML);
              if (child !== wrapper.lastChild) document.execCommand('insertParagraph');
            }
          } else {
            document.execCommand('insertHTML', false, wrapper.innerHTML || '');
          }

          const {
            isAtEnd
          } = isFocusedAtEndOrStart(targetRef.current);
          if (isAtEnd) document.execCommand('insertParagraph');
          updateLinkedMentions();
          handlePastedImages(createMediaLink);
        }
      }
    } catch (err) {
      console.error('## Error on pasting', err);
    }
  }, [markdownProcessor, dropFilesCallback, insertFiles, webEmojiToImage, updateLinkedMentions, createMediaLink]);
  const onCopy = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    getMarkdownFromCopiedText(e, plaintext);
    e.preventDefault();
  }, [plaintext]);
  const onCut = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    getMarkdownFromCopiedText(e, plaintext);
  }, [plaintext]);
  const clear = useCallback(() => targetRef.current && (targetRef.current.innerHTML = ''), []);
  const concat = useCallback((text: string) => targetRef.current && (targetRef.current.innerHTML = targetRef.current.innerHTML + text), []);

  const getInitialNodeValue = (draft: string) => {
    let mentions: MentionAttachments = initialMentions;
    if (persistKey) draft = localStorage.getItem(persistKey) || '';
    const {
      text: draftText,
      mentions: draftMentions
    } = preHydrateText(draft); // Merge initialMentions and draftMentions

    let user_mentions = [...(mentions.user_mentions || []), ...(draftMentions.user_mentions || [])];
    user_mentions = Object.values(Object.fromEntries(user_mentions.map(m => [m.uuid, m])));
    let asset_mentions = [...(mentions.asset_mentions || []), ...(draftMentions.asset_mentions || [])];
    asset_mentions = Object.values(Object.fromEntries(asset_mentions.map(m => [`${m.symbol}:${m.type}`, m])));
    mentions = {
      user_mentions,
      asset_mentions
    };
    return <Formatter text={draftText} mentions={mentions} processor={markdownProcessor} />;
  }; // @TODO how to handle when this value is updated?


  let [initialNode, setInitialNode] = useState<ReactNode>(getInitialNodeValue(persistKey ? localStorage.getItem(persistKey) || '' : initialText || ''));

  const overrideText = (text: string) => setInitialNode(getInitialNodeValue(text)); // dev check for something I worry may be problematic in the future


  let initialValueRef = useRef(initialText);

  if (initialText !== initialValueRef.current) {
    initialValueRef.current = initialText;
    console.error('## initialValue changed, this is not supported and the change will be ignored');
  } // helper must be started with command, critically it wraps the helper key in a span for use by InputHelper positioning


  const startHelper = useCallback((type: HelperType) => document.execCommand('insertHtml', false, type), []);
  useEffect(() => {
    if (!targetRef.current) return;
    let observer = new MutationObserver(() => {
      requestIdleCallback(() => listenersRef.current.forEach(cb => targetRef.current && cb(targetRef.current)), {
        timeout: 500
      });
    });
    observer.observe(targetRef.current, {
      childList: true,
      // observe direct children
      subtree: true,
      // and lower descendants too
      attributes: true,
      characterData: true
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    // Reinforce checking for isEmpty after first render
    checkMention(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // @NOTE: This was necessary for posts, please check if this is still necessary
  // function handleInputKeyDown(_e: React.KeyboardEvent<HTMLDivElement>) {
  //   if (plaintext && targetRef.current && markdownProcessor === postsMarkdown) {
  //     removeStyleAndFormattingTags(targetRef.current)
  //   }
  // }

  let input = <div {...dragHandlers} className={cx(className, baseTextStyle, baseStyle, plaintext ? simplifiedStyle : textAreaStyle, isEmpty && !isUploading && 'empty', isUploading && 'uploading')} ref={targetRef} contentEditable suppressContentEditableWarning={true} onInput={checkMention} onMouseUp={checkMention} onKeyUp={checkLiveEdit} // onKeyDown={handleInputKeyDown}
  role="textbox" data-placeholder={placeholder} onPaste={onPaste} onCopy={onCopy} onCut={onCut}>
      {initialNode}
    </div>;
  const getInputRange = useCallback(() => {
    return getRangeInTarget(targetRef.current) || null;
  }, []);
  useLayoutEffect(() => {
    targetRef.current?.addEventListener('click', (e: MouseEvent) => {
      // prevent links from working inside of the input.
      // we may want to extend this to other elements too, not sure yet
      // @ts-ignore ??
      if (e.srcElement?.tagName === 'A' && e.srcElement?.closest('.' + baseStyle)) {
        e.preventDefault();
        e.stopImmediatePropagation(); // opens link modal for links in EditPost that aren't asset/user mentions

        if (editPost && // @ts-ignore
        !e.srcElement?.classList.contains('asset-mention') && // @ts-ignore
        !e.srcElement?.classList.contains('user-mention')) {
          const range = document.createRange(); // @ts-ignore

          range.selectNodeContents(e.srcElement); // @ts-ignore

          openLinkModal(range, modal, e.srcElement.href);
        }
      }
    });
  }, [modal, editPost]);
  useLayoutEffect(() => {
    if (initialText && !hasInitialized) {
      checkLiveEdit();

      if (shouldFocusStart) {
        focus(true);
        setHasInitialized(true);
      }
    }
  }, [checkLiveEdit, focus, hasInitialized, initialText, mentionData, shouldFocusStart]);
  useKeyDown([...HelperOwnedKeys, 'ArrowLeft', 'ArrowRight'], e => {
    // noop if we are not focused
    if (!getInputRange()) return; // This prevents navigating the cursor while mentioning

    if (mentionData && HelperOwnedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    } // Prevent soft breaks


    if (e.key === 'Enter' && (e.shiftKey || e.altKey || lineBreak !== 'allow')) {
      e.preventDefault();
      const allowedByModifierKeys = lineBreak === 'modifier-keys-only' && (e.shiftKey || e.altKey);
      return allowedByModifierKeys || lineBreak === 'allow' ? document.execCommand('insertParagraph') : null;
    }

    if (onNavigate && ['ArrowLeft', 'ArrowRight'].includes(e.key)) onNavigate(e);
    if (['ArrowLeft', 'ArrowRight'].includes(e.key) && e.shiftKey === false) requestIdleCallback(() => checkMention(), {
      timeout: 500
    });
  });
  const canSave = useMemo(() => {
    return !isUploading;
  }, [isUploading]);
  const helper: Helper = useMemo(() => {
    return {
      search: mentionData?.search || false,
      leftText: mentionData?.leftText || false,
      type: mentionData?.type || false,
      onSelect: async (link: string, text: string, payload: HelperPayload, preventSpace: boolean) => {
        if (!targetRef.current || !mentionData?.type) return;
        addMentionToElement(targetRef.current, link, text, payload, mentionData, preventSpace, updateLinkedMentions);
        !preventSpace && setMentionedWithAutoSpace(text);
      }
    };
  }, [mentionData, updateLinkedMentions]);
  const editor: TextEditorValue = {
    getMarkdown,
    getPlaintext,
    clear,
    focus,
    input,
    inputRef: targetRef.current,
    insertFiles,
    listen,
    getInputRange,
    canSave,
    helper,
    startHelper,
    concat,
    getPlaintextLength,
    overrideText,
    getMentions
  };
  useEffect(() => {
    if (persistKey) {
      const debouncedPersist = debounce(() => {
        if (!targetRef.current) return;
        const markdown = getMarkdown();

        try {
          if (markdown) localStorage.setItem(persistKey, markdown);
          if (!markdown) localStorage.removeItem(persistKey);
        } catch (err) {
          captureException(err, {
            context: 'localStorage save in TextEditor'
          });
        }
      }, 500);
      return listen(debouncedPersist);
    }
  }, [persistKey, listen, getMarkdown]); // @NOTE: This was necessary for posts, please check if this is still necessary
  // useEffect(() => {
  //   if (initialText && plaintext && targetRef.current && markdownProcessor === postsMarkdown) {
  //     wrapPostLinks(targetRef.current)
  //   }
  // }, [initialText, markdownProcessor, plaintext])

  useImperativeHandle(ref, () => editor);
  return <TextEditorContext.Provider value={editor}>
      <MentionsContext.Provider value={initialMentions || {}}>{children}</MentionsContext.Provider>
    </TextEditorContext.Provider>;
}

let Provider = React.forwardRef(_Provider);

function Input(props: HTMLDivProps) {
  let {
    input
  } = useContext(TextEditorContext);
  if (input) return React.cloneElement(input, { ...input.props,
    className: cx(input.props.className, props.className),
    ...props
  });
  return null;
}

export const TextEditor = {
  Provider,
  Input
};
const baseStyle = "bsokgrl";
const simplifiedStyle = "sd9a807";
const textAreaStyle = "t194xvhz";
export const imageWrapperClass = "i94mj1n";

require("../../../.linaria-cache/packages/oxcart/src/scopes/text-editor/TextEditor.linaria.module.css");