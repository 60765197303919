import { css } from '@linaria/core';
import { Colors, FontWeights, PanelGridGap, SmallMobileBreakpoint, ZIndexLayers } from './constants';
import { hiddenScrollbar, scrollbarMuted, scrollbar } from './scrollbar';
export const scrollYHidden = "s9qhs4k";
export const scrollY = "s18d5gjp";
export const scrollYMuted = "s4usweg";
export const noBoxShadow = "n21ovgr";
export const flexCentered = "f19yohy7";
export const flexJustifiedCenter = "f2lwg7h";
export const flexJustified = "f9vqxa7";
export const linkClass = "lz3b66v";
export const gradientText = "g1ldw5ih";
export const relative = "rywp185";
export const devTransparent = "d1p1cmxh";
export const devTransparentOutline = "d1bkaaef";
const CardWidthSmall = '288px';
const CardWidthMax = '560px';
export const responsiveImageContainer = "rqqo9ny";
export const mobileGutterContainer = "m1oipkuw";
export const responsiveImage = "r1wbl5cx";

require("../../.linaria-cache/packages/oxcart/src/theme/AtomicClasses.linaria.module.css");