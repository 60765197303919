import React, { useCallback, ReactNode, useRef } from 'react';
import { Image } from '../theme/NewIcons';
import { IconButton } from '../composing/CSButton';
import { css, cx } from '@linaria/core';
import useKeyPress, { Keys } from '@commonstock/common/src/utils/useKeyPress';
type Props = {
  Button?: typeof IconButton;
  disabled?: boolean;
  onFileUpload: (file: any) => void;
  formatFn?: (file: any) => Promise<any>;
  multiple?: boolean;
  children?: ReactNode;
  validMimeTypes?: string[];
  className?: string;
  keyupKeys?: Keys;
} & React.ComponentProps<typeof IconButton>;
export const ImageMimes = ['image/jpeg', 'image/png'];
const MediaMimes = [...ImageMimes, 'image/gif', 'video/mp4'];

const UploadFileButton = ({
  Button = IconButton,
  disabled,
  onFileUpload,
  formatFn,
  multiple,
  children,
  validMimeTypes = MediaMimes,
  className,
  keyupKeys = [],
  ...rest
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const formatFile = useCallback((file: File) => {
    if (formatFn) return formatFn(file);
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => resolve(e.target?.result);

      reader.onerror = error => reject(error);
    });
  }, [formatFn]);
  const onClick = useCallback(() => {
    if (disabled) return;
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = validMimeTypes.join(',');
    input.multiple = Boolean(multiple); // @ts-ignore

    input.onchange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        let invalid = [];
        Array.from(e.target.files).map(async file => {
          if (validMimeTypes.includes(file.type)) {
            const formattedFile = await formatFile(file);
            onFileUpload(formattedFile);
          } else {
            invalid.push(file);
          }
        });

        if (invalid.length) {
          // @TODO better if we do this as a toast or inline error
          alert(`${invalid.length} ${invalid.length > 1 ? 'files' : 'file'} could not be processed due to unsupported media type`);
        }
      }
    };

    input.click();
  }, [validMimeTypes, disabled, formatFile, multiple, onFileUpload]);
  useKeyPress(keyupKeys, e => {
    e.stopPropagation();

    if (document.activeElement === buttonRef.current) {
      onClick();
    }
  }, buttonRef.current || window);
  return <Button {...rest} ref={buttonRef} className={cx(buttonClass, className)} onClick={onClick} disabled={!!disabled} aria-label={'Upload image'}>
      {!children && <Image size={1.25} />}
      {children}
    </Button>;
};

export default UploadFileButton;
const buttonClass = "b1gokspu";

require("../../.linaria-cache/packages/oxcart/src/components/UploadFileButton.linaria.module.css");